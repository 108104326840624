import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Success = () => {
  const render = () => (
    <div>
      <h3>Thank you for your purchase!</h3>
      <Button variant="primary" as={Link} to="/home">
        Start creating
      </Button>
    </div>
  );

  return (
    <Container>
      <Row className="py-3">
        <Col align="center">{render()}</Col>
      </Row>
    </Container>
  );
};
