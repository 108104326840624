import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../features/auth/authSlice";
import { useHistory } from "react-router-dom";
import { useVerifyMutation } from "../app/services/api";
import {
  setIsAuthenticated,
  getIsAuthenticated,
} from "../features/auth/authSlice";

export const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const { push } = useHistory();

  const [verifyToken, { isLoading }] = useVerifyMutation();

  useEffect(() => {
    const prepare = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        try {
          await verifyToken({ token: accessToken }).unwrap();
          dispatch(setIsAuthenticated(true));
        } catch (err) {
          dispatch(logOut());
          push("/expired");
        }
      }
    };
    prepare();
  }, [dispatch, verifyToken, push]);

  return [isAuthenticated, isLoading];
};
