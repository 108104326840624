import { useGetProfileQuery } from "../../app/services/api";
import { Navbar, Spinner } from "react-bootstrap";

export const NavigationBarStatus = () => {
  const {
    data: profile = {
      email: "",
      secs: "",
      id: "",
    },
    isFetching,
  } = useGetProfileQuery();

  return isFetching ? (
    <Spinner />
  ) : (
    <Navbar.Text>
      Signed in as: {profile.email} <br />
      Balance: <b>{profile.secs} seconds left</b>
    </Navbar.Text>
  );
};
