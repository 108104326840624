import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Button } from "react-bootstrap";
import { logOut } from "./authSlice";
import { useHistory } from "react-router-dom";

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logOut());
    window.location.reload();
  }, [dispatch]);
  const { push } = useHistory();

  const onBackClicked = (e) => {
    push("/");
  };

  return (
    <Container>
      <p>See you soon!</p>
      <Button variant="primary" type="submit" onClick={onBackClicked}>
        Back to home page
      </Button>
    </Container>
  );
};
