import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  useUploadMediaMutation,
  useValidateImageMutation,
  useS3UploadMutation,
} from "../../app/services/api";
import {
  states,
  getImgState,
  setImgState,
  getImgErrorMessage,
} from "./coreSlice";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../../utils/canvasUtils";
import * as Sentry from "@sentry/browser";

export const AddImage = ({
  showAddImage,
  handleCloseAddImage,
  imgURL,
  setImgURL,
  handleClickImgInput,
}) => {
  // Component state
  const state = useSelector(getImgState);
  const imgErrorMessage = useSelector(getImgErrorMessage);

  // Other hooks
  const dispatch = useDispatch();

  // const { seconds, minutes } = useStopwatch({ autoStart: true });
  // Reset state on unmount
  useEffect(() => () => dispatch(setImgState(states.READY)), [dispatch]);

  // Mutations
  const [uploadMedia] = useUploadMediaMutation();
  const [validateImage] = useValidateImageMutation();
  const [s3Upload] = useS3UploadMutation();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [, setCroppedImage] = useState(null);

  const resetState = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setImgURL(null);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const updateCroppedImage = async () => {
    try {
      const image = await getCroppedImg(imgURL, croppedAreaPixels);
      setCroppedImage(image);
      return image;
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const onUploadClicked = async (e) => {
    try {
      dispatch(setImgState(states.WIP));

      // Get local .png crop blob
      const blobURL = await updateCroppedImage();
      const blob = await fetch(blobURL).then((r) => r.blob());

      // Ask for image presigned upload
      const { url, fields, uuid } = await uploadMedia({
        type: blob.type,
      }).unwrap();

      // Upload to S3
      const s3Result = await s3Upload({ url, file: blob, fields });

      // Bubble up error if S3 upload failed - not throwing from inside s3Upload
      // to avoid RTKQ warning about unhandled exceptions inside custom queryFns
      if (s3Result.error) {
        throw new Error(s3Result.error);
      }

      // Validate image
      await validateImage({ uuid });

      handleCloseAddImage(false);
    } catch (err) {
      Sentry.captureException(err);
      dispatch(setImgState(states.ERROR));
    }
  };

  const renderCenterComponent = () => {
    let component;
    switch (state) {
      case states.READY:
      case states.WIP:
        component = (
          <div className="crop-container">
            <Cropper
              image={imgURL}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        );
        break;
      case states.ERROR:
        let message;
        switch (imgErrorMessage) {
          case "no_face":
            message = "No face found, please try again";
            break;
          case "multiple_faces":
            message = "More than one face was found, please try again";
            break;
          default:
            message =
              "Upload failed, please try again. If this issue persists, contact support";
            break;
        }
        component = (
          <div className="d-flex flex-column align-items-center">
            <h5>
              <b>Add image failed</b>
            </h5>
            <span style={{ textAlign: "center" }}>{message}.</span>
          </div>
        );
        break;
      default:
        break;
    }
    return <div className="d-flex justify-content-center">{component}</div>;
  };

  const renderBottomButtons = () => {
    return state === states.READY || state === states.WIP ? (
      <Button
        className="my-0"
        onClick={onUploadClicked}
        disabled={state === states.WIP}
      >
        {state === states.READY ? (
          "Upload for free"
        ) : (
          <div>
            <Spinner as="span" size="sm" role="status" animation="border" />
            &nbsp;&nbsp;Preparing file
          </div>
        )}
      </Button>
    ) : state === states.ERROR ? (
      <Button
        className="my-0"
        variant="secondary"
        onClick={() => {
          resetState();
          handleClickImgInput();
          setImgState(states.READY);
        }}
      >
        Retry
      </Button>
    ) : (
      <Button
        className="my-0"
        variant="secondary"
        onClick={() => {
          handleCloseAddImage();
          resetState();
        }}
      >
        Close
      </Button>
    );
  };

  return (
    <div className="add-media">
      <Modal
        show={showAddImage}
        onHide={() => {
          handleCloseAddImage();
          resetState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="section-title">
              <h2>Add image</h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderCenterComponent()}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="add-media-bottom-btns">{renderBottomButtons()}</div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
