import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "account",
  initialState: { connectionId: null },
  reducers: {
    setConnectionId(state, action) {
      state.connectionId = action.payload;
    },
  },
});

export const { setConnectionId } = slice.actions;

export default slice.reducer;
