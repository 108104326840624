import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getSelectedVidFace, setSelectedVidFace } from "./coreSlice";
var classNames = require("classnames");

export const VidFaceThumbnail = ({ item: renderedVidFace }) => {
  const dispatch = useDispatch();
  const [imgHasLoaded, setImgHasLoaded] = useState(false);
  const selectedVidFace = useSelector(getSelectedVidFace);
  const isRenderedSelected = renderedVidFace.num === selectedVidFace?.num;

  var imageClass = classNames({
    "scroll-item-selected": isRenderedSelected,
    "scroll-item": !isRenderedSelected,
    "scroll-item-image": true,
  });

  const onVidFaceClick = () => {
    dispatch(setSelectedVidFace(renderedVidFace));
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-center">
      <div className={imageClass}>
        <img
          className="face"
          style={{
            display: imgHasLoaded ? "block" : "none",
          }}
          alt=""
          src={renderedVidFace.src}
          onClick={onVidFaceClick}
          onLoad={() => setImgHasLoaded(true)}
        />
        <Spinner
          style={{
            display: imgHasLoaded ? "none" : "block",
            width: "52px",
            height: "52px",
          }}
          animation="border"
          variant="secondary"
          size="lg"
        />
      </div>
    </div>
  );
};
