import { Form, Button, Container, Spinner, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { useResetConfirmMutation } from "../../app/services/api";

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1, -1);

export const ResetConfirm = ({ match, dispatch }) => {
  const matchParams = useMemo(() => match.params, [match]);
  const [resetConfirm, { error: errorsResetConfirm, isLoading, isSuccess }] =
    useResetConfirmMutation();

  const schema = Yup.object().shape({
    new_password: Yup.string()
      .required("Password is a required field")
      .min(6, "Password is too short, must be 6 characters or more"),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("new_password")], "Passwords must match"),
  });

  const onSubmit = async (values) => {
    const { userId, token } = matchParams;
    await resetConfirm({
      uid: userId,
      token: token,
      new_password: values.new_password,
      re_new_password: values.confirmPassword,
    });
  };

  return (
    <Container className="p-0 mt-3">
      {isSuccess ? (
        <>
          <Row className="my-3 justify-content-center">
            <p>Password reset successfully!</p>
          </Row>
          <Row className="my-3 justify-content-center">
            <Col xs="auto">
              <Button variant="primary" as={Link} to="/login">
                Login
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            new_password: "",
            confirmPassword: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik02">
                  <Form.Label>Choose a new password</Form.Label>
                  <Form.Control
                    type="password"
                    name="new_password"
                    placeholder="Enter password"
                    value={values.new_password}
                    onChange={handleChange}
                    isInvalid={
                      touched.new_password &&
                      (errors.new_password ||
                        errorsResetConfirm?.data.new_password?.find(
                          (x) => x !== undefined
                        ))
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.new_password ||
                      capitalize(
                        errorsResetConfirm?.data.new_password?.find(
                          (x) => x !== undefined
                        )
                      )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik03">
                  <Form.Label>Confirm new password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={5}>
                  <Button type="submit">
                    {isLoading ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Reset password"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};
