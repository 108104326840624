const local = {
  apiGateway: {
    URL: "wss://e7caq5wpz1.execute-api.us-west-2.amazonaws.com/production",
  },
  api: {
    URL: "http://localhost:8000/",
    // URL: "https://api.wannafake.me/",
  },
  recaptcha: {
    siteKey: "6Ld3trQeAAAAAHvIhrXoq2qhfPRQeG1NRC6_Kq2q",
  },
  tagManager: {
    gtmId: "GTM-NPZVR3N",
  },
  paypal: {
    clientId:
      "ASHq4GEEQowwxmPsHNyQi0Qjbeidd7Ei4pkZ5YM5DOqfdxU_7rNNZf1wJmoZwmlOHyDS_xgL8TkF1O2Q",
  },
};

const staging = {
  apiGateway: {
    URL: "wss://e7caq5wpz1.execute-api.us-west-2.amazonaws.com/production",
  },
  api: {
    URL: "https://api.wannafake.me/",
  },
  recaptcha: {
    siteKey: "6Ld3trQeAAAAAHvIhrXoq2qhfPRQeG1NRC6_Kq2q",
  },
  tagManager: {
    gtmId: "GTM-NPZVR3N",
  },
  paypal: {
    clientId:
      "ASHq4GEEQowwxmPsHNyQi0Qjbeidd7Ei4pkZ5YM5DOqfdxU_7rNNZf1wJmoZwmlOHyDS_xgL8TkF1O2Q",
  },
};

const prod = {
  apiGateway: {
    URL: "wss://trkvtl9ag9.execute-api.eu-west-3.amazonaws.com/production",
  },
  api: {
    URL: "https://api.wannafake.com/",
  },
  recaptcha: {
    siteKey: "6Le4OcceAAAAAPqhylNpvzHiWTIHf9RbrvMpphKO",
  },
  tagManager: {
    gtmId: "GTM-K64FN5P",
  },
  paypal: {
    clientId:
      "AW-aMhfrujqrj2EtaWKCvrCeGJphYDxT_GfA_fpeg7m0Ha5hjInCVyqzET9yqZw7b8NA0Ac84zenZTbU",
  },
};

const config_ =
  process.env.REACT_APP_STAGE === "production"
    ? prod
    : process.env.REACT_APP_STAGE === "staging"
    ? staging
    : local;

export const config = {
  // Common config values here
  MAX_ITEMS: 50,
  DAYS_LEFT_WARNING: 7,
  ...config_,
};

export default config;
