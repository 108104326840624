import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Container, Row, Col, Stack, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MediaScroller } from "./MediaScroller";
import { VideoFaceScroller } from "./VideoFaceScroller";
import { AddVideo } from "./AddVideo";
import {
  useGetProfileQuery,
  useGetImagesQuery,
  useGetVideosQuery,
  useGetSwapsQuery,
  useCreateVideoMutation,
} from "../../app/services/api";
import {
  getSelectedVideo,
  getSelectedImage,
  getSelectedVidFace,
  getShowAddImage,
  getCurrentPage,
  getPageSize,
  setSelectedVideo,
  setSelectedImage,
  setSelectedVidFace,
  setShowAddImage,
  setImgState,
  setVidState,
  setCurrentPage,
  states,
} from "./coreSlice";
import { ConverterHelpModal } from "../../utils/HelpModals";
import { AddImage } from "./AddImage";

export const Converter = () => {
  const VIDEO_SIZE_LIMIT = 500e6; // Cannot trim videos larger than ~500MB
  const IMAGE_SIZE_LIMIT = 30e6; // Cannot edit images larger than ~30MB

  let history = useHistory();
  const dispatch = useDispatch();

  // Component state
  const [hasBudget, setHasBudget] = useState(false);
  const [selectedVideoHasMultipleFaces, setSelectedVideoHasMultipleFaces] =
    useState(false);
  const [combinationAlreadyExists, setCombinationAlreadyExists] =
    useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [imgURL, setImgURL] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [vidURL, setVidURL] = useState(null);
  const [vidFile, setVidFile] = useState(null);
  // showAddImage state was moved up to core slice to be set by websocket messages
  const [showAddVideo, setShowAddVideo] = useState(false);
  const [isCreatingSwap, setIsCreatingSwap] = useState(false);

  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = () => setShowHelp(true);
  const handleCloseAddImage = (changeState = true) => {
    changeState && dispatch(setShowAddImage(false));
    hiddenFileImgInput.current.value = null; // Trigger onFileChange even if user selects same file again
  };
  const handleCloseAddVideo = () => {
    setShowAddVideo(false);
    hiddenFileVidInput.current.value = null; // Trigger onFileChange even if user selects same file again
  };

  // Store state
  const selectedImage = useSelector(getSelectedImage);
  const showAddImage = useSelector(getShowAddImage);
  const selectedVideo = useSelector(getSelectedVideo);
  const selectedVidFace = useSelector(getSelectedVidFace);
  const currentPage = useSelector(getCurrentPage);
  const pageSize = useSelector(getPageSize);

  // Queries
  const { refetch: refetchProfile, data: profile } = useGetProfileQuery();
  const {
    refetch: refetchImages,
    data: images,
    isLoading: isLoadingImages,
  } = useGetImagesQuery();
  const {
    refetch: refetchVideos,
    data: videos,
    isLoading: isLoadingVideos,
  } = useGetVideosQuery();
  const { data: { created_videos: swaps } = {} } = useGetSwapsQuery({
    page: currentPage,
    pageSize,
  });

  // Mutations
  const [createVideo, { isLoading: isLoadingCreateVideo }] =
    useCreateVideoMutation();

  // Reset selected media if said media was deleted
  // Only select OK videos
  useEffect(() => {
    const visibleImages = images?.filter((img) => !img.hidden);
    const visibleVids = videos?.filter(
      (vid) => !vid.hidden && vid.status === "OK"
    );
    if (visibleImages?.length) {
      dispatch(setSelectedImage(visibleImages[0]));
    } else {
      dispatch(setSelectedImage(null));
    }
    if (visibleVids?.length) {
      dispatch(setSelectedVideo(visibleVids[0]));
    } else {
      dispatch(setSelectedVideo(null));
    }
  }, [images, videos, dispatch]);

  // Check if enough budget for converting video
  useEffect(() => {
    if (profile && selectedVideo) {
      setHasBudget(profile.secs - Math.ceil(selectedVideo.duration) >= 0);
    }
  }, [profile, selectedVideo]);

  // Check if selected video has more than one face detected
  useEffect(() => {
    const hasMultipleFaces = selectedVideo && selectedVideo.id_imgs.length > 1;
    setSelectedVideoHasMultipleFaces(hasMultipleFaces);
    hasMultipleFaces
      ? dispatch(setSelectedVidFace(selectedVideo.id_imgs[0]))
      : dispatch(setSelectedVidFace(null));
  }, [selectedVideo, dispatch]);

  // Check if specific video-image pair wasn't converted before
  useEffect(() => {
    // console.log("pro", apiSlice.getRunningOperationPromise);
    if (swaps) {
      setCombinationAlreadyExists(
        swaps.some(
          (e) =>
            e.status !== "FAIL" &&
            e.image_id === selectedImage?.id &&
            e.video_id === selectedVideo?.id &&
            (e.selected_img === selectedVidFace?.num ||
              selectedVidFace === null) // This last check is not need for vids with <2 faces ie. null selectedVidFace
        )
      );
    }
  }, [selectedImage, selectedVideo, selectedVidFace, swaps]);

  const onCreateVideoClicked = async (e) => {
    if (isCreatingSwap) return;
    setIsCreatingSwap(true);

    try {
      await createVideo({
        imageId: selectedImage.id,
        videoId: selectedVideo.id,
        vidFaceNum: selectedVidFace?.num,
        _pageSize: pageSize,
      });
      dispatch(setCurrentPage(1));
      refetchProfile();
      history.push("/gallery");
    } finally {
      setIsCreatingSwap(false);
    }
  };

  // Image and video file uploading preparation
  const hiddenFileImgInput = useRef(null);
  const handleClickImgInput = (e) => {
    hiddenFileImgInput.current.click();
  };
  const hiddenFileVidInput = useRef(null);
  const handleClickVidInput = (e) => {
    hiddenFileVidInput.current.click();
  };

  const onImgFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > IMAGE_SIZE_LIMIT) {
        alert("Image is too large. Max file size: 30MB");
        return;
      }
      setImgFile(file);
      setImgURL(URL.createObjectURL(file));
      dispatch(setImgState(states.READY));
      dispatch(setShowAddImage(true));
    }
  };
  const onVidFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > VIDEO_SIZE_LIMIT) {
        alert("Video is too large. Max file size: 500MB");
        return;
      }
      setVidFile(file);
      setVidURL(URL.createObjectURL(file));
      dispatch(setVidState(states.READY));
      setShowAddVideo(true);
    }
  };

  const renderButton = () => {
    if (!hasBudget && selectedVideo) {
      return (
        <Button disabled={true} variant="warning" type="submit">
          Not enough seconds
        </Button>
      );
    } else if (combinationAlreadyExists) {
      return (
        <Button disabled={true} variant="warning" type="submit">
          Combination already exists
        </Button>
      );
    } else {
      return (
        <Button
          disabled={
            isCreatingSwap ||
            !selectedImage ||
            !selectedVideo ||
            (selectedVideoHasMultipleFaces && !selectedVidFace)
          }
          variant="primary"
          type="submit"
          onClick={onCreateVideoClicked}
          className="swap-button"
        >
          {isLoadingCreateVideo ? (
            <Spinner as="span" size="sm" role="status" animation="border" />
          ) : (
            "Create swap"
          )}
        </Button>
      );
    }
  };

  return isLoadingImages || isLoadingVideos ? (
    <div className="loading-screen">
      <Spinner animation="border" variant="secondary" />
      <span className="my-3">Loading...</span>
    </div>
  ) : (
    <Container className="converter">
      <Row className="mt-3">
        <Col>
          {/* <div className="alert alert-warning">
            {"\u24D8"}&nbsp;&nbsp;
            {
              "Service might experience disruptions over the next hour due to maintenance. Thank you for your understanding."
            }
          </div> */}
          <div className="section-title">
            <h2>Home</h2>
            <Button
              className="p-0 m-0 help-btn"
              variant="secondary"
              size="md"
              onClick={handleShowHelp}
            >
              <span>?</span>
            </Button>
          </div>
          <AddImage
            showAddImage={showAddImage}
            handleCloseAddImage={handleCloseAddImage}
            imgFile={imgFile}
            imgURL={imgURL}
            setImgURL={setImgURL}
            refetchImages={refetchImages}
            handleClickImgInput={handleClickImgInput}
          />
          <AddVideo
            showAddVideo={showAddVideo}
            handleCloseAddVideo={handleCloseAddVideo}
            vidFile={vidFile}
            vidURL={vidURL}
            refetchVideos={refetchVideos}
          />
          <ConverterHelpModal
            showHelp={showHelp}
            handleCloseHelp={handleCloseHelp}
          />
        </Col>
      </Row>
      <Row className="my-3">
        <Col className="px-0" align="right">
          <Button as={Link} variant="primary" to="/gallery">
            Go to my swaps
          </Button>
        </Col>
      </Row>
      <Row className="my-3">
        <input
          type="file"
          accept="image/*"
          ref={hiddenFileImgInput}
          onChange={onImgFileChange}
          style={{ display: "none" }}
        />
        <MediaScroller
          type="img"
          items={images}
          onAddMediaClick={handleClickImgInput}
        />
      </Row>
      <Row className="my-3">
        <input
          type="file"
          accept="video/mp4, video/mpeg, video/ogg, video/webm, video/3gpp, video/3gpp2"
          ref={hiddenFileVidInput}
          onChange={onVidFileChange}
          style={{ display: "none" }}
        />
        <MediaScroller
          type="vid"
          items={videos}
          onAddMediaClick={handleClickVidInput}
        />
      </Row>
      {selectedVideoHasMultipleFaces ? (
        <Row className="my-3">
          <VideoFaceScroller items={selectedVideo.id_imgs} />
        </Row>
      ) : null}
      <Row className="my-3">
        <Col xs="auto">
          <Stack gap={3}>
            {selectedImage ? (
              selectedVideo ? (
                <div>
                  <h3>
                    Video cost (duration): {Math.ceil(selectedVideo?.duration)}{" "}
                    secs
                  </h3>
                  <p className="mb-0 mt-1">
                    <i>
                      Balance: <b>{profile.secs} seconds left</b>
                    </i>
                  </p>
                </div>
              ) : (
                <h3>No video selected</h3>
              )
            ) : (
              <h3>No face selected</h3>
            )}
            {renderButton()}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};
