import { useEffect, useMemo } from "react";
import { Container, Button, Spinner, Row, Col, Stack } from "react-bootstrap";
import { useActivateMutation } from "../../app/services/api";
import { Link } from "react-router-dom";

export const Activate = ({ match, dispatch }) => {
  const [activate, { isSuccess, isError }] = useActivateMutation();
  const matchParams = useMemo(() => match.params, [match]);

  useEffect(() => {
    const { userId, token } = matchParams;
    const activateFn = async () => {
      await activate({ uid: userId, token });
    };
    activateFn();
  }, [activate, matchParams]);

  const render = () => {
    if (isSuccess) {
      return (
        <div>
          <h3>Your account is now active!</h3>
          <Button variant="primary" as={Link} to="/login">
            Login
          </Button>
        </div>
      );
    } else if (isError) {
      return <h3>Something went wrong activating your account.</h3>;
    } else {
      return (
        <Stack gap={2} style={{ alignItems: "center" }}>
          <span>Activating account...</span>
          <Spinner animation="border" size="sm" />
        </Stack>
      );
    }
  };

  return (
    <Container>
      <Row className="py-3">
        <Col align="center">{render()}</Col>
      </Row>
    </Container>
  );
};
