import { Col, Container, Row } from "react-bootstrap";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { VidFaceThumbnail } from "./VidFaceThumbnail";

export const VideoFaceScroller = ({ items }) => {
  return (
    <Container className="media-scroller">
      <Row className="mb-0">
        <Col>
          <h5>...replacing this face</h5>
        </Col>
      </Row>
      <Row>
        <ScrollMenu>
          {items.map((item) => {
            return (
              <VidFaceThumbnail
                context="scroll"
                itemId={item.src}
                key={item.src}
                item={item}
              />
            );
          })}
        </ScrollMenu>
      </Row>
    </Container>
  );
};
