import { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { config } from "../../config";

const backend_ = config.api.URL;

export const Potato = () => {
  const [john, setJohn] = useState("");
  const [doe, setDoe] = useState("");
  const [magic, setMagic] = useState("");
  const [surprise, setSurprise] = useState("");
  const [out, setOut] = useState("Bleh");

  const submitShit = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    const token = localStorage.getItem("accessToken");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `JWT ${token}`);
    fetch(backend_ + "account/potato/", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ john, doe, magic, surprise }),
    })
      .then((res) => res.json())
      .then((data) => {
        setOut(data.data);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Container className="my-5">
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>John</Form.Label>
          <Form.Control
            type="email"
            placeholder="John"
            onChange={(e) => {
              setJohn(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Doe</Form.Label>
          <Form.Control
            type="email"
            placeholder="Doe"
            onChange={(e) => {
              setDoe(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Magic</Form.Label>
          <Form.Control
            type="email"
            placeholder="Magic"
            onChange={(e) => {
              setMagic(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Surprise</Form.Label>
          <Form.Control
            type="email"
            placeholder="Surprise"
            onChange={(e) => {
              setSurprise(e.target.value);
            }}
          />
        </Form.Group>

        <Button variant="primary" type="submit" onClick={submitShit}>
          Submit
        </Button>
        <span>{out}</span>
      </Form>
    </Container>
  );
};
