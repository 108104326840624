import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  Spinner,
  Stack,
} from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { GalleryItem } from "./GalleryItem";
import { EditMediaButtons } from "./EditMediaButtons";
import { useGetSwapsQuery } from "../../app/services/api";
import { MdOutlineInfo } from "react-icons/md";
import { setCurrentPage, getCurrentPage, getPageSize } from "./coreSlice";
var classNames = require("classnames");

export const Gallery = () => {
  const { push } = useHistory();
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const currentPage = useSelector(getCurrentPage);
  const pageSize = useSelector(getPageSize);
  const {
    data: {
      created_videos: swaps,
      total: totalItems,
      expiring: numExpiringSwaps,
    } = {},
    isLoading,
    isFetching,
  } = useGetSwapsQuery({
    page: currentPage,
    pageSize,
  });
  const [notificationCount, setNotificationCount] = useState(null);

  const numVisibleItems = swaps?.filter((i) => !i.hidden)?.length;
  const numHiddenItems = swaps?.filter((i) => i.hidden)?.length;

  // Pagination handles
  const handlePageChange = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  // Render the Pagination component
  const pagination =
    totalItems > pageSize ? (
      <div className="custom-pagination">
        <PaginationControl
          page={currentPage}
          between={3}
          total={totalItems}
          limit={pageSize}
          changePage={handlePageChange}
          ellipsis={2}
        />
      </div>
    ) : null;

  const onBackClicked = () => {
    if (editMode) {
      setEditMode(false);
    } else {
      push("/home");
    }
  };

  useEffect(() => {
    swaps && setNotificationCount(numExpiringSwaps);
  }, [swaps, numExpiringSwaps]);

  return isLoading ? (
    <Container className="mt-3">
      <Row className="mb-3">
        <Col>
          <Stack gap={2} style={{ alignItems: "center" }}>
            <span>Loading your swaps...</span>
            <Spinner animation="border" />
          </Stack>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="mx-2 mt-3">
      <Row>
        <Col className="px-0">
          <h2>{editMode ? "My swaps options" : "My swaps"}</h2>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col className="px-0">
          <div className="d-flex flex-row">
            <Button variant="secondary" onClick={onBackClicked}>
              Back
            </Button>
            {/* <div style={{ width: "80px" }}></div> */}
            <Button
              className="mx-2 notification-button"
              variant="secondary"
              hidden={editMode}
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              Options
              <span className="notification-badge">{notificationCount}</span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="px-0">
          <div className="d-flex flex-row">{pagination}</div>
        </Col>
      </Row>
      {numVisibleItems > 0 || isLoading || isFetching || editMode ? null : (
        <Row className="my-2">
          <Col>
            {
              <i>
                {numHiddenItems > 0
                  ? `${numHiddenItems} hidden swap video${
                      numHiddenItems > 1 ? "s" : ""
                    } here`
                  : "No swaps created yet."}
              </i>
            }
          </Col>
        </Row>
      )}
      {!editMode ? null : (
        <Row className="mb-2 mt-2">
          <Col xs="auto" className="px-0">
            <div className="d-flex flex-row align-items-center alert alert-secondary notification-banner-gallery m-0 p-1">
              <MdOutlineInfo className="me-1" size={19} />
              <span>
                You have {notificationCount} swap
                {notificationCount !== 1 && "s"} expiring soon.
              </span>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {isFetching ? (
            <Stack className="my-4" gap={2} style={{ alignItems: "center" }}>
              <span>Loading swaps...</span>
              <Spinner animation="border" />
            </Stack>
          ) : (
            <ListGroup variant="flush" className="px-0">
              {swaps
                ?.filter((swap) => {
                  if (editMode) {
                    return swap.status !== "FAIL";
                  } else {
                    return !swap.hidden;
                  }
                })
                .map((swap) => {
                  let willDeleteSoon = swap.days_left <= 7;
                  var listGroupRowClass = classNames({
                    "align-items-center": true,
                    "scroll-item-will-delete": willDeleteSoon,
                  });

                  return (
                    <Container key={swap.id} className="px-0">
                      <Row className={listGroupRowClass} xs="auto">
                        <Col className="px-0">
                          <GalleryItem swap={swap} editMode={editMode} />
                        </Col>
                        {editMode && (
                          <Col className="px-0">
                            <div className="d-flex flex-column">
                              <EditMediaButtons type={"swap"} item={swap} />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Container>
                  );
                })}
            </ListGroup>
          )}
        </Col>
      </Row>
      {isFetching ? null : (
        <Row>
          <Col className="px-0">
            <div className="d-flex flex-row mb-2">{pagination}</div>
          </Col>
        </Row>
      )}
    </Container>
  );
};
