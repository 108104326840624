import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./services/api";
import authReducer from "../features/auth/authSlice";
import accountReducer from "../features/account/accountSlice";
import coreReducer from "../features/core/coreSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    core: coreReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
