import React from "react";
import { Redirect, Route } from "react-router-dom";

export function ProtectedRoute({ isPublicOnly, ...rest }) {
  // Use to show a route to users who either have or do not have credentials (token)
  // If isPublicOnly auth users will NOT see the route

  const hasCredentials = localStorage.getItem("accessToken");
  const show = isPublicOnly ? !hasCredentials : hasCredentials;
  return show ? <Route {...rest} /> : <Redirect to="/" />;
}
