import { Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const Fallback = () => {
  const { push } = useHistory();

  return (
    <Container>
      <Row className="my-3">
        <h2>Oops! Something went wrong...</h2>
      </Row>
      <Row>
        <p>Our team was notified and is working hard to fix this issue.</p>
        <p>
          If the issue persists, please email us at <b>support@wannafake.com</b>
        </p>
      </Row>
      <Row xs="auto">
        <Col>
          <Button
            variant="primary"
            type="submit"
            onClick={(e) => {
              push("/");
              window.location.reload();
            }}
          >
            Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
