import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { MdOutlineInfo } from "react-icons/md";
import { useGetImagesQuery, useGetVideosQuery } from "../../app/services/api";
import { EditMediaListItem } from "./EditMediaListItem";
import daysLeftFilter from "../../utils/misc";

export const EditMediaList = ({ type }) => {
  const { data: images, isLoading: isLoadingImages } = useGetImagesQuery();
  const { data: videos, isLoading: isLoadingVideos } = useGetVideosQuery();
  const media = type === "img" ? images : videos;

  const [notificationCount, setNotificationCount] = useState(null);
  useEffect(() => {
    media && setNotificationCount(daysLeftFilter(media));
  }, [media]);
  const mediaType = type === "img" ? "face" : "video";

  return isLoadingImages || isLoadingVideos ? (
    <Container className="my-3">
      <Row>
        <Spinner>Loading...</Spinner>
      </Row>
    </Container>
  ) : (
    <Container className="mt-3">
      <Row>
        <Col>
          <h2>Edit {mediaType}s</h2>
        </Col>
      </Row>
      <Row className="my-0">
        <Col align="left">
          <Button as={Link} variant="secondary" to="/home">
            Back
          </Button>
        </Col>
      </Row>
      <Row className="my-2">
        <Col xs="auto" align="left">
          <div className="d-flex flex-row align-items-center alert alert-secondary notification-banner-gallery m-0 p-1">
            <MdOutlineInfo className="me-1" size={19} />
            <span>
              You have {notificationCount} {mediaType}
              {notificationCount !== 1 && "s"} expiring soon.
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup className="edit-media-list-group" variant="flush">
            {media
              ?.filter((item) => item.status !== "FAIL")
              .map((item) => (
                <EditMediaListItem key={item.id} type={type} item={item} />
              ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};
