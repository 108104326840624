import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setIsAuthenticated, logOut } from "../../features/auth/authSlice";
import { config } from "../../config";

const backend_ = config.api.URL;

const baseQuery = fetchBaseQuery({
  baseUrl: backend_,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      headers.set("authorization", `JWT ${token}`);
    }
    return headers;
  },
});

const interceptBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    switch (result.error.status) {
      case 401:
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken) {
          api.dispatch(logOut());
          break;
        }

        const refreshResult = await baseQuery(
          {
            url: "auth/jwt/refresh/",
            method: "POST",
            body: { refresh: refreshToken },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          const accessToken = refreshResult.data.access;
          localStorage.setItem("accessToken", accessToken);
          api.dispatch(setIsAuthenticated(true));

          // Retry baseQuery with new token after refresh
          const newArgs = { ...args, body: { token: accessToken } };
          result = await baseQuery(newArgs, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
        break;
      default:
        break;
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: interceptBaseQuery,
  tagTypes: ["images", "videos", "created_videos"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/jwt/create/",
        method: "POST",
        body: credentials,
      }),
    }),
    verify: builder.mutation({
      query: (token) => ({
        url: "auth/jwt/verify/",
        method: "POST",
        body: token,
      }),
    }),
    reset: builder.mutation({
      query: (email) => ({
        url: "auth/users/reset_password/",
        method: "POST",
        body: email,
      }),
    }),
    resetConfirm: builder.mutation({
      query: (newCredentials) => ({
        url: "auth/users/reset_password_confirm/",
        method: "POST",
        body: newCredentials,
      }),
    }),
    signup: builder.mutation({
      query: (formData) => ({
        url: "auth/users/",
        method: "POST",
        body: formData,
      }),
    }),
    activate: builder.mutation({
      query: (formData) => ({
        url: "auth/users/activation/",
        method: "POST",
        body: formData,
      }),
    }),
    checkout: builder.mutation({
      query: (checkoutData) => ({
        url: "account/checkout/",
        method: "POST",
        body: checkoutData,
      }),
    }),
    order: builder.mutation({
      query: (orderData) => ({
        url: "account/order/",
        method: "POST",
        body: orderData,
      }),
    }),
    confirm: builder.mutation({
      query: (confirmData) => ({
        url: "account/confirm/",
        method: "POST",
        body: confirmData,
      }),
    }),
    getProfile: builder.query({
      query: () => "account/profile/",
    }),
    uploadMedia: builder.mutation({
      query: (mediaData) => ({
        url: "upload-media/",
        method: "POST",
        body: mediaData,
      }),
    }),
    editMedia: builder.mutation({
      query: (editInfo) => ({
        url: "edit-media/",
        method: "POST",
        body: editInfo,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const { op, type, mediaId, currentPage, pageSize } = patch;
        let queryToUpdate;
        let args = id;
        switch (type) {
          case "img":
            queryToUpdate = "getImages";
            break;
          case "vid":
            queryToUpdate = "getVideos";
            break;
          case "swap":
            queryToUpdate = "getSwaps";
            args = { page: currentPage, pageSize: pageSize };
            break;
          default:
            break;
        }

        const patchResult = dispatch(
          apiSlice.util.updateQueryData(queryToUpdate, args, (draft) => {
            if (type === "swap") {
              const ix = draft.created_videos.findIndex(
                (obj) => obj.id === mediaId
              ); // Returns -1 if not found
              switch (op) {
                case "delete":
                  draft.created_videos.splice(ix, 1);
                  break;
                case "hide":
                  draft.created_videos[ix].hidden = true;
                  break;
                case "show":
                  draft.created_videos[ix].hidden = false;
                  break;
                default:
                  break;
              }
            } else {
              const ix = draft.findIndex((obj) => obj.id === mediaId); // Returns -1 if not found
              switch (op) {
                case "delete":
                  draft.splice(ix, 1);
                  break;
                case "hide":
                  draft[ix].hidden = true;
                  break;
                case "show":
                  draft[ix].hidden = false;
                  break;
                default:
                  break;
              }
            }
          })
        );

        try {
          await queryFulfilled;
          // Best UX? TBD...
          // op === "delete" &&
          //   dispatch(apiSlice.util.invalidateTags(["created_videos"]));
        } catch {
          patchResult.undo();
        }
      },
    }),
    validateImage: builder.mutation({
      query: (imageData) => ({
        url: "validate-img/",
        method: "POST",
        body: imageData,
      }),
    }),
    validateVideo: builder.mutation({
      // query: (videoData) => ({
      //   url: "validate-vid/",
      //   method: "POST",
      //   body: videoData,
      // }),
      queryFn: async (args, _api, _extraOptions, baseQuery) => {
        try {
          const newArgs = {
            url: "validate-vid/",
            method: "POST",
            body: args,
          };
          const resp = await baseQuery(newArgs, _api, _extraOptions);
          if (!resp.ok) {
            return { error: "Non-OK status error" };
          }
          return { data: "Success" };
        } catch (err) {
          return { error: "Network error" };
        }
      },
    }),
    createVideo: builder.mutation({
      query: (createData) => ({
        url: "create-vid/",
        method: "POST",
        body: createData,
      }),
      invalidatesTags: ["created_videos"],
      // Pessimistic update: Add to cache newest swap (that just started processing after this mutation is done)
      // before websocket notifications arrive
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: newCreation } = await queryFulfilled;
          const args = { page: 1, pageSize: patch._pageSize };
          dispatch(
            apiSlice.util.updateQueryData("getSwaps", args, (draftSwaps) => {
              draftSwaps.created_videos.unshift(newCreation); // ie. push to beginning not end of array (as this will be the most recent swap)
            })
          );
        } catch {}
      },
    }),
    updateConnection: builder.mutation({
      query: (connectionId) => ({
        url: "update-connection/",
        method: "POST",
        body: connectionId,
      }),
    }),
    getImages: builder.query({
      query: () => "converter/images/",
      providesTags: ["images"],
      transformResponse: (resp) => resp.images,
    }),
    getVideos: builder.query({
      query: () => "converter/videos/",
      providesTags: ["videos"],
      transformResponse: (resp) => resp.videos,
    }),
    getSwaps: builder.query({
      query: ({ page, pageSize }) =>
        `gallery/videos/?page=${page}&page_size=${pageSize}`,
      providesTags: ["created_videos"],
      transformResponse: (resp) => resp,
    }),
    s3Upload: builder.mutation({
      queryFn: async (args, _api, _extraOptions, _baseQuery) => {
        const { url, file, fields } = args;

        const formData = new FormData();
        // Append all fields from presigned post data
        Object.keys(fields).forEach((key) => {
          formData.append(key, fields[key]);
        });
        formData.append("file", file);

        try {
          const resp = await fetch(url, {
            method: "POST",
            body: formData,
          });
          if (!resp.ok) {
            return { error: "Non-OK status error" };
          }
          return { data: "Success" };
        } catch (err) {
          return { error: "Network error" };
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useVerifyMutation,
  useSignupMutation,
  useResetMutation,
  useResetConfirmMutation,
  useActivateMutation,
  useCheckoutMutation,
  useOrderMutation,
  useConfirmMutation,
  useUploadMediaMutation,
  useEditMediaMutation,
  useValidateImageMutation,
  useValidateVideoMutation,
  useCreateVideoMutation,
  useUpdateConnectionMutation,
  useS3UploadMutation,
  useGetProfileQuery,
  useGetImagesQuery,
  useGetVideosQuery,
  useGetSwapsQuery,
} = apiSlice;
