import { Container, Row, Col } from "react-bootstrap";

export const Contacts = () => {
  return (
    <Container className="contacts py-3" fluid>
      <Row className="mb-3">
        <h4>Contact us</h4>
      </Row>
      <Row className="links mb-3">
        <Col>
          Client support:
          <br />
          <a href="mailto:support@wannafake.com">support@wannafake.com</a>
        </Col>
      </Row>
      <Row className="links mb-3">
        <Col>
          Product feedback, suggestions:
          <br />
          <a href="mailto:feedback@wannafake.com">feedback@wannafake.com</a>
        </Col>
      </Row>
    </Container>
  );
};
