import { Container } from "react-bootstrap";

export const Privacy = () => {
  return (
    <Container fluid className="mt-3">
      <div>
        <h1>
          <span>Privacy Policy</span>
        </h1>
        <p>
          <span>Updated November 14th, 2023</span>
        </p>
        <p>
          <span>
            This Privacy Policy describes the privacy practices of Wannafake in
            connection with the Wannafake.com website (the “Site”).
          </span>
        </p>
        <h4>
          <span>SUMMARY</span>
        </h4>
        <p>
          <span>A few key points about Wannafake’s privacy practices:</span>
        </p>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>
                Wannafake is an online video creation tool that allows users to
                upload and swap faces on videos using a single photo.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                This swapping is done automatically using AI algorithms.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                We use third-party cloud providers – namely, Amazon Web Services
                – to process and edit photos and videos (“Media”).
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Wannafake only uploads to the cloud the media that you
                specifically select for media creation.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Media are securely stored and encrypted on cloud servers during
                the media creation process.
              </span>
            </p>
          </li>
        </ul>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>
                Uploaded and created media remain in the cloud for a maximum
                period of 30 days after upload or creation, and are
                automatically deleted after this period.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                You can easily delete any uploaded or created media inside
                Wannafake at any time, without waiting for the 30-day automatic
                deletion.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                We do not use media you provide when you use Wannafake for any
                reason other than to provide you with the video swapping
                functionality of Wannafake.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span>
            If you have any questions about our privacy practices, please email
            us at privacy@wannafake.com
          </span>
        </p>
        <h4>
          <span>PERSONAL INFORMATION WE COLLECT</span>
        </h4>
        <p>
          <span>
            When you use Wannafake, we may collect information about you,
            including:
          </span>
        </p>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>
                Images and video (“Media”) you provide when you use Wannafake,
                via your camera or camera roll (if you have granted us
                permission to access your camera or camera roll on mobile
                devices), or via files uploaded from any other device. We obtain
                only the specific media you chose to use Wannafake. We encrypt
                on the cloud every media file that you upload using Wannafake.
                Please note that while we do not require or request any metadata
                attached to the media you upload, metadata (including, for
                example, geotags) may be associated with your media files by
                default.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Website usage information</span>
              <span>
                , such as information about how you use Wannafake and interact
                with us, including the date and time you last used Wannafake.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Purchase history</span>
              <span>
                , if you choose to make purchases at Wannafake, such as
                confirmation that you are a Wannafake client.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Device data</span>
              <span>
                , such as your computer and mobile device operating system type
                and version number, manufacturer and model, browser type, screen
                resolution, IP address (and the associated country in which you
                are located), the website you visited before visiting our Site;
                and other information about the device you are using to visit
                Wannafake.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Online activity data</span>
              <span>
                , such as information about your use of and actions on
                Wannafake, including pages or screens you viewed, how long you
                spent on a page or screen, navigation paths between pages or
                screens, information about your activity on a page or screen,
                access times, and length of access. Our service providers and
                certain third parties (e.g., online advertising networks and
                their clients) also may collect this type of information over
                time and across third-party websites and mobile applications.
                This information may be collected on Wannafake using cookies,
                browser web storage (also known as locally stored objects, or
                “LSOs”), web beacons, and similar technologies. We may collect
                this information directly or through our use of third-party
                software development kits (“SDKs”). SDKs may enable third
                parties to collect information directly from Wannafake.
              </span>
            </p>
          </li>
        </ul>
        <h4>
          <span>HOW WE USE YOUR PERSONAL INFORMATION</span>
        </h4>
        <p>
          <span>
            We do not use the media you provide when you use Wannafake for any
            reason other than to provide you with the video swapping
            functionality of Wannafake. We may use information other than the
            media you provide for the following purposes:
          </span>
        </p>
        <p>
          <span>To operate and improve Wannafake:</span>
        </p>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>Enable you to use Wannafake’s features;</span>
            </p>
          </li>
          <li>
            <p>
              <span>Establish and maintain your account;</span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Communicate with you about Wannafake, including by sending you
                announcements, updates, and security alerts, which we may send
                through email, and responding to your requests, questions and
                feedback;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Provide technical support and maintenance for Wannafake; and
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Perform statistical analysis about use of Wannafake (including
                through the use of Google Analytics).
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span>To send you marketing and promotional communications.</span>
          <span>
            &nbsp;We may send you marketing email communications as permitted by
            law. You will have the ability to opt-out of our marketing and
            promotional communications by notifying us at privacy@wannafake.com.
          </span>
          <p></p>
          <span>For compliance, fraud prevention, and safety.</span>
          <span>
            &nbsp;We may use your personal information and disclose it to law
            enforcement, government authorities, and private parties as we
            believe necessary or Appropriate to: (a) protect our, your or
            others’ rights, privacy, safety or property (including by making and
            defending legal claims); (b) enforce the terms of use that govern
            the Wannafake service; and (c) protect, investigate and deter
            against fraudulent, harmful, unauthorized, unethical or illegal
            activity.
          </span>
        </p>
        <p>
          <span>With your consent.</span>
          <span>
            &nbsp;In some cases, we may specifically ask for your consent to
            collect, use or share your personal information, such as when
            required by law.
          </span>
        </p>
        <p>
          <span>To create anonymous, aggregated or de-identified data.</span>
          <span>
            &nbsp;We may create anonymous, aggregated or de-identified data from
            your personal information and other individuals whose personal
            information we collect. We make personal information into anonymous,
            aggregated or de-identified data by removing information that makes
            the data personally identifiable to you. We may use this anonymous,
            aggregated or de-identified data and share it with third parties for
            our lawful business purposes.
          </span>
        </p>
        <h4>
          <span>HOW WE SHARE YOUR PERSONAL INFORMATION</span>
        </h4>
        <p>
          <span>
            We do not disclose user media to third parties (with the exception
            of uploading encrypted media to our cloud provider Amazon Web
            Services to allow for your use of Wannafake). We may share your
            non-image and non-video information in the following circumstances:
          </span>
        </p>
        <p>
          <span>Service providers.</span>
          <span>
            &nbsp;We may share your personal information with services providers
            that perform services on our behalf or help us operate Wannafake
            (such as customer support, hosting, analytics, email delivery,
            marketing, and database management services). These third parties
            may use your personal information only as directed or authorized by
            us and in a manner consistent with this Privacy Policy, and are
            prohibited from using or disclosing your information for any other
            purpose.
          </span>
        </p>
        <p>
          <span>Advertising partners.</span>
          <span>
            &nbsp;When we use third-party cookies and other tracking tools, our
            advertising partners may collect information from your device to
            help us analyze usage of Wannafake and advertise Wannafake (and
            related content) elsewhere online.
          </span>
        </p>
        <p>
          <span>Professional advisors.</span>
          <span>
            &nbsp;We may disclose your personal information to professional
            advisors, such as lawyers, bankers, auditors and insurers, where
            necessary in the course of the professional services that they
            render to us.
          </span>
        </p>
        <p>
          <span>For compliance, fraud prevention and safety.</span>
          <span>
            &nbsp;We may share your personal information for the compliance,
            fraud prevention and safety purposes described above.
          </span>
        </p>
        <p>
          <span>Business transfers.</span>
          <span>
            &nbsp;We may sell, transfer or otherwise share some or all of our
            business or assets, including your personal information, in
            connection with a business transaction (or potential business
            transaction) such as a corporate divestiture, merger, consolidation,
            acquisition, reorganization or sale of assets, or in the event of
            bankruptcy or dissolution.
          </span>
        </p>
        <h4>
          <span>COMPLIANCE WITH LAW</span>
        </h4>
        <p>
          <span>
            We may be required to use and share your personal information to
            comply with applicable laws, lawful requests, and legal process,
            such as to respond to subpoenas or requests from government
            authorities.
          </span>
        </p>
        <h4>
          <span>YOUR CHOICES</span>
        </h4>
        <p>
          <span>
            In this section, we describe the rights and choices available to all
            users. Users who are located within Europe can find additional
            information about their rights below.
          </span>
        </p>
        <p>
          <span>Opt out of marketing communications.</span>
          <span>
            &nbsp;You may opt out of marketing-related communications by
            emailing us at privacy@wannafake.com.
          </span>
        </p>
        <p>
          <span>Cloud processing.</span>
          <span>
            &nbsp;You may easily delete your uploaded information, including
            uploaded and created media, from the cloud before the automatic
            30-day period deletion takes place by using the 'delete'
            functionality offered by Wannafake.
          </span>
        </p>
        <p>
          <span>Cookies &amp; Browser Web Storage.</span>
          <span>
            &nbsp;Most browsers let you remove or reject cookies. To do this,
            follow the instructions in your browser settings. Many browsers
            accept cookies by default until you change your settings. Please
            note that if you set your browser to disable cookies, the Site may
            not work properly. Similarly, your browser settings may allow you to
            clear your browser web storage.
          </span>
        </p>
        <p>
          <span>Choosing not to share your personal information.</span>
          <span>
            &nbsp;Where we are required by law to collect your personal
            information, or where we need your personal information in order to
            provide Wannafake to you, if you do not provide this information
            when requested (or you later ask to delete it), we may not be able
            to provide you with our services. We will tell you what information
            you must provide to use Wannafake by designating it as required at
            the time of collection or through other appropriate means.
          </span>
        </p>
        <h4>
          <span>OTHER SITES, MOBILE APPLICATIONS AND SERVICES</span>
        </h4>
        <p>
          <span>
            Wannafake may contain links to other websites, mobile applications,
            and other online services operated by third parties. These links are
            not an endorsement of, or representation that we are affiliated
            with, any third party. In addition, our content may be included on
            web pages or in mobile applications or online services that are not
            associated with us. We do not control third party websites, mobile
            applications or online services, and we are not responsible for
            their actions. Other websites, mobile applications and online
            services follow different rules regarding the collection, use and
            sharing of your personal information. We encourage you to read the
            privacy policies of the other websites, mobile applications and
            online services you use.
          </span>
        </p>
        <h4>
          <span>SECURITY PRACTICES</span>
        </h4>
        <p>
          <span>
            We use commercially reasonable security practices to help keep the
            information collected through Wannafake secure and take reasonable
            steps to verify your identity before granting you access to your
            account (if you have an account with us). However, Wannafake cannot
            ensure the security of any information you transmit to Wannafake or
            guarantee that information on Wannafake may not be accessed,
            disclosed, altered, or destroyed.
          </span>
        </p>
        <p>
          <span>
            Please do your part to help us. You are responsible for maintaining
            the confidentiality of your login information and device
            identifiers, and for controlling access to communications between
            you and Wannafake, at all times. We are not responsible for the
            functionality, privacy, or security measures of any other
            organization.
          </span>
        </p>
        <h4>
          <span>RETENTION</span>
        </h4>
        <p>
          <span>
            Wannafake automatically deletes uploaded and created media 30 days
            after upload/creation. This retention period allows you to more
            easily generate new content during this time.
          </span>
        </p>
        <p>
          <span>
            With respect to non-media information that we may collect, we will
            retain such information in a personally identifiable format only for
            as long as necessary to fulfill the purposes we have set out in this
            Privacy Policy. You may also ask that we delete your information by
            emailing us at privacy@wannafake.com.
          </span>
        </p>
        <h4>
          <span>CROSS-BORDER DATA TRANSFERS</span>
        </h4>
        <p>
          <span>
            We store the information we collect in connection with Wannafake on
            Amazon Web Services. For Amazon Web Services, we specify the US and
            Europe as data storage locations. Your personal information may be
            accessed by our service providers in other locations outside of your
            state, province, or country.
          </span>
        </p>
        <h4>
          <span>CHILDREN</span>
        </h4>
        <p>
          <span>
            Wannafake is not directed at children under the age of 13, and our
            Terms of Use do not allow children under 13 years of age to use
            Wannafake. If we learn that we have collected personal information
            of a child under the age of 13, we will delete it. We encourage
            parents with concerns to contact us at privacy@wannafake.com.
          </span>
        </p>
        <h4>
          <span>CHANGES TO THE PRIVACY POLICY</span>
        </h4>
        <p>
          <span>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by updating the date of this Privacy Policy and posting it on
            Wannafake. We may, and if required by law, will, provide
            notification of changes in another way that we believe is reasonably
            likely to reach you, such as through Wannafake.
          </span>
        </p>
        <p>
          <span>
            Any modifications to this Privacy Policy will be effective upon our
            posting the new terms and/or upon implementation of the new changes
            on Wannafake (or as otherwise indicated at the time of posting). In
            all cases, your continued use of Wannafake after the posting of any
            modified Privacy Policy indicates your acceptance of the terms of
            the modified Privacy Policy.
          </span>
        </p>
        <h4>
          <span>HOW TO CONTACT US</span>
        </h4>
        <p>
          <span>
            Please direct any questions or comments about this Policy or privacy
            practices to privacy@wannafake.com.
          </span>
        </p>
        <h4>
          <span>NOTICE TO EUROPEAN USERS</span>
        </h4>
        <p>
          <span>
            The information provided in this “Notice to European Users” section
            applies only to individuals who reside in Europe.
          </span>
        </p>
        <p>
          <b>Personal information: </b>
          <span>
            References to “personal information” in this Privacy Policy are
            equivalent to “personal data” governed by European data protection
            legislation.
          </span>
        </p>
        <p>
          <b>Controller and EU Representative: </b>
          <span>
            Wannafake is the controller of your personal information covered by
            this Privacy Policy for purposes of European data protection
            legislation.
          </span>
        </p>
        <p>
          <b>Legal bases for processing: </b>
          <span>
            We use your personal information only as permitted by law. Our legal
            bases for processing the personal information described in this
            Privacy Policy are described below:
          </span>
        </p>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>To operate and improve Wannafake</span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "1rem",
              }}
            >
              <li aria-level={2}>
                <p>
                  <span>
                    Processing is necessary to perform the contract governing
                    our provision of services in connection with Wannafake, or
                    to take steps that you request prior to signing up for
                    Wannafake. If we have not entered into a contract with you,
                    we process your personal information based on our legitimate
                    interest in providing the services you access and request in
                    connection with Wannafake.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span>
                To send you marketing and promotional communications, for
                compliance, fraud prevention and safety
              </span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "1rem",
              }}
            >
              <li aria-level={2}>
                <p>
                  <span>
                    These activities constitute our legitimate interests. We do
                    not use your personal information for these activities where
                    our interests are overridden by the impact on you (unless we
                    have your consent or are otherwise required or permitted to
                    by law).
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span>Compliance with law</span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "1rem",
              }}
            >
              <li aria-level={2}>
                <p>
                  <span>
                    Processing is necessary to comply with our legal
                    obligations.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span>With your consent</span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "1rem",
              }}
            >
              <li aria-level={2}>
                <p>
                  <span>
                    Processing is based on your consent. Where we rely on your
                    consent you have the right to withdraw it any time in the
                    manner indicated when you consent or in Wannafake.
                  </span>
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <h4>
          <span>Your rights</span>
        </h4>
        <p>
          <span>
            European data protection laws give you certain rights regarding your
            personal information. If you are located within the European Union,
            you may ask us to take the following actions in relation to your
            personal information that we hold:
          </span>
        </p>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>Access.</span>
              <span>
                &nbsp;Provide you with information about our processing of your
                personal information and give you access to your personal
                information.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Correct.</span>
              <span>
                &nbsp;Update or correct inaccuracies in your personal
                information.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Delete.</span>
              <span>&nbsp;Delete your personal information.</span>
            </p>
          </li>
          <li>
            <p>
              <span>Transfer.</span>
              <span>
                &nbsp;Transfer a machine-readable copy of your personal
                information to you or a third party of your choice.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Restrict.</span>
              <span>
                &nbsp;Restrict the processing of your personal information.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Object.</span>
              <span>
                &nbsp;Object to our reliance on our legitimate interests as the
                basis of our processing of your personal information that
                impacts your rights.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span>
            You may submit these requests by email to privacy@wannafake.com.
          </span>
        </p>
        <p>
          <span>
            If you would like to submit a complaint about our use of your
            personal information or our response to your requests regarding your
            personal information, you may contact us at privacy@wannafake.com or
            submit a complaint to the data protection regulator in your
            jurisdiction.
          </span>
        </p>
        <h4>
          <span>NOTICE TO CALIFORNIA RESIDENTS</span>
        </h4>
        <p>
          <span>
            We are required by the California Consumer Privacy Act of 2018
            (“CCPA”) to provide to California residents an explanation of how we
            collect, use and share their personal information, and of the rights
            and choices we offer California residents regarding our handling of
            their personal information.
          </span>
        </p>
        <p>
          <span>
            We do not sell personal information. As we explain in this Privacy
            Policy, we use cookies and other tracking technologies to analyze
            site traffic and use.&nbsp;
          </span>
        </p>
        <p>
          <span>
            In addition to disclosures of personal information to our service
            providers and professional advisors, for compliance, fraud
            prevention and safety, and in connection with a business transfer,
            as previously described in this Privacy Policy, we describe below
            our privacy practices with respect to each personal information item
            of our California consumers:
          </span>
        </p>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>Photographs</span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "1rem",
              }}
            >
              <li aria-level={2}>
                <p>
                  <span>CCPA-defined category</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>Physical description</span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>Sources of personal information</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>You</span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>
                    Purposes for which we may collect and use the personal
                    information
                  </span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>To operate and improve Wannafake</span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>Data sharing:</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>
                        Shared with third-party platforms at your direction
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Shared with cloud service providers (Amazon Web
                        Services)
                      </span>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span>Website usage information</span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "1rem",
              }}
            >
              <li aria-level={2}>
                <p>
                  <span>CCPA-defined category</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>Online identifiers</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Internet or network information</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Inferences</span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>Sources of personal information</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>You</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Automatic collection</span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>
                    Purposes for which we may collect and use the personal
                    information
                  </span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>To operate and improve Wannafake</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        To send you marketing and promotional communications
                      </span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>Data sharing:</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>
                        Shared with third-party platforms (e.g., social media
                        networks) at your direction
                      </span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>
                        Shared with cloud service providers (Amazon Web
                        Services)
                      </span>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <p>
              <span>Purchase history</span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "1rem",
              }}
            >
              <li aria-level={2}>
                <p>
                  <span>CCPA-defined category</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>Commercial information</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Online identifiers</span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>Sources of personal information</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>
                        Wannafake payments provider through which you made your
                        purchases (i.e., Stripe)
                      </span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>
                    Purposes for which we may collect and use the personal
                    information
                  </span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>To operate and improve Wannafake</span>
                    </p>
                  </li>
                </ul>
              </li>
              <li aria-level={2}>
                <p>
                  <span>Data sharing:</span>
                </p>
                <ul
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingInlineStart: "1rem",
                  }}
                >
                  <li>
                    <p>
                      <span>None</span>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h3>
          <span>CALIFORNIA RESIDENTS’ PRIVACY RIGHTS</span>
        </h3>
        <p>
          <span>
            Except as excluded from the scope of this notice above, the CCPA
            grants California residents the following rights.
          </span>
        </p>
        <ul
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingInlineStart: "1rem",
          }}
        >
          <li>
            <p>
              <span>Information.</span>
              <span>
                &nbsp;You can request information about how we have collected,
                used and shared your personal information during the past 12
                months. We have made this information available to California
                residents without having to request it by including it in this
                notice, in the above chart.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Access.</span>
              <span>
                &nbsp;You can request a copy of the personal information that we
                maintain about you.
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>Deletion.</span>
              <span>
                &nbsp;You can ask us to delete the personal information that we
                collected or maintain about you.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span>
            Please note that the CCPA limits these rights by, for example,
            prohibiting us from providing certain sensitive information in
            response to an access request and limiting the circumstances in
            which we must comply with a deletion request. If we deny your
            request, we will communicate our decision to you.
          </span>
        </p>
        <p>
          <span>
            You are entitled to exercise the rights described above free from
            discrimination.
          </span>
        </p>
        <h3>
          <span>HOW TO SUBMIT A REQUEST</span>
        </h3>
        <p>
          <span>
            To request access to or deletion of personal information email us at
            privacy@wannafake.com.
          </span>
        </p>
        <p>
          <span>Identity verification.</span>
          <span>
            &nbsp;The CCPA requires us to verify the identity of the individual
            submitting a request to access or delete personal information before
            providing a substantive response to the request. We may attempt to
            verify your identity by asking you to confirm information that we
            have on file about you or your interactions with us. Where we ask
            for additional personal information to verify your identity, we will
            only use it to verify your identity or your authority to make the
            request on behalf of another consumer.
          </span>
        </p>
        <p>
          <span>Authorized agents.</span>
          <span>
            &nbsp;California residents can empower an “authorized agent” to
            submit requests on their behalf. We will require the authorized
            agent to have written authorization confirming such authority.
          </span>
        </p>
      </div>
    </Container>
  );
};
