import { Button, Modal } from "react-bootstrap";

export const ConverterHelpModal = ({ showHelp, handleCloseHelp }) => {
  return (
    <Modal show={showHelp} onHide={handleCloseHelp}>
      <Modal.Header closeButton>
        <Modal.Title>Homepage tutorial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The main page of Wannafake.
        <br />
        <br />
        Here you can combine faces and videos that you uploaded to create unique
        face swap videos.
        <br />
        <br />
        First, upload faces and videos by clicking the '+' button.
        <br />
        <br />
        Next, select one face and one video.
        <br />
        <br />
        Next, if your video has many faces, select which one will be replaced.
        <br />
        <br />
        Finally, click 'Create swap' to generate a face swap video with the face
        and video you selected.
        <br />
        <br />
        Try out different combinations and have fun!
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseHelp}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AddVideoHelpModal = ({ showHelp, handleCloseHelp }) => {
  return (
    <Modal show={showHelp} onHide={handleCloseHelp}>
      <Modal.Header closeButton>
        <Modal.Title>Add video tutorial</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Add videos to put faces later.
        <br />
        <br />
        Here you can upload videos for free (ie. no seconds are used) to use
        later with different faces.
        <br />
        <br />
        Use the blue trim bar to select only the parts of the video you want.
        This makes sure that later when you put faces in them you only pay for
        the duration you want.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseHelp}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
