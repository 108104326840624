import {
  ListGroup,
  Container,
  Row,
  Col,
  Stack,
  Spinner,
} from "react-bootstrap";
import { useGetProfileQuery } from "../../app/services/api";

export const Settings = () => {
  const {
    data: profile = {
      email: "",
      secs: "",
      id: "",
      emailPreference: "",
    },
    isFetching,
  } = useGetProfileQuery();

  return isFetching ? (
    <Container className="p-0">
      <Row className="mb-3">
        <Col>
          <Stack gap={2} style={{ alignItems: "center" }}>
            <span>Loading...</span>
            <Spinner animation="border" />
          </Stack>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="my-3">
      <Row>
        <Col>
          <h2>Settings</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup variant="flush" className="settings-list-group">
            <ListGroup.Item>
              <b>User ID</b>&nbsp;<i>{profile.id}</i>
            </ListGroup.Item>
            <ListGroup.Item>
              <b>Email</b>&nbsp;<i>{profile.email}</i>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};
