import { Form, Button, Container, Spinner, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useResetMutation } from "../../app/services/api";

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1, -1);

export const Reset = () => {
  const [login, { error: errorsLogin, isLoading, isSuccess }] =
    useResetMutation();

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is a required field"),
  });

  const onSubmit = async (values) => {
    await login({
      email: values.email,
    });
  };

  return (
    <Container className="py-0 my-3">
      {isSuccess ? (
        <Row>
          <h6>
            We've sent you an email with instructions to reset your password.
          </h6>
          <br />
          <h6 className="mb-0">
            If you do not see the email in your inbox, please check your junk
            email folder.
          </h6>
        </Row>
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            email: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik01">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={
                      touched.email &&
                      (errors.email ||
                        errorsLogin?.data.email?.find((x) => x !== undefined))
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email ||
                      capitalize(
                        errorsLogin?.data.email?.find((x) => x !== undefined)
                      )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={5}>
                  <Button type="submit">
                    {isLoading ? (
                      <Spinner animation="border" role="status" size="sm" />
                    ) : (
                      "Send email to reset password"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};
