import { ProgressBar, ListGroup, Button } from "react-bootstrap";
import ReactPlayer from "react-player";
import { IoMdClose } from "react-icons/io";
import { useEditMediaMutation } from "../../app/services/api";
import { useSelector } from "react-redux";
import { getCurrentPage, getPageSize } from "../../features/core/coreSlice";
var classNames = require("classnames");

export const GalleryItem = ({ swap, editMode }) => {
  const currentPage = useSelector(getCurrentPage);
  const pageSize = useSelector(getPageSize);
  const [editMedia] = useEditMediaMutation();
  const deleteItem = (id) => {
    let args = {
      op: "delete",
      type: "swap",
      mediaId: id,
      currentPage,
      pageSize,
    };
    editMedia(args);
  };

  const itemClass = classNames({
    "gallery-item-container": !editMode,
    "gallery-item-container-edit": editMode,
    transparent: swap.status === "FAIL" || swap.hidden,
  });

  const renderVideo = () => {
    let component;

    switch (swap.status) {
      case "OK":
        component = (
          <div className={itemClass}>
            <img alt="" className={"face"} src={swap.image_src} />
            <ReactPlayer
              url={swap.src}
              playing={true}
              width="100%"
              height="100%"
              light={swap.thumb_src}
              controls
              // style={{ minWidth: "270px", minHeight: "152px" }}
            />
          </div>
        );
        break;
      case "FAIL":
        component = (
          <div className={itemClass}>
            <div>
              <img alt="" className="face" src={swap.image_src} />
            </div>
            <div>
              <img alt="" className="video-thumb" src={swap.thumb_src} />
            </div>
            <div className="dismiss-text">
              <b>Swap failed</b>
              <br />
              Your seconds were automatically restored and our team was notified
              of this.
            </div>
            <Button
              variant="danger"
              size="sm"
              onClick={() => deleteItem(swap.id)}
            >
              <IoMdClose />
            </Button>
          </div>
        );
        break;
      default:
        let progress;
        switch (swap.status) {
          case "WIP0":
            progress = 5;
            break;
          case "WIP1":
            // WIP1 represents the map states, and occupies 85% of the progress bar
            progress = 10 + swap.progress * 85;
            break;
          case "WIP2":
            progress = 95;
            break;
          default:
            break;
        }
        component = (
          <div className={itemClass}>
            <div>
              <img alt="" className="face" src={swap.image_src} />
            </div>
            <div>
              <img alt="" className="video-thumb" src={swap.thumb_src} />
            </div>
            <div>
              <ProgressBar animated now={progress} />
            </div>
          </div>
        );
        break;
    }
    return component;
  };

  return <ListGroup.Item>{renderVideo()}</ListGroup.Item>;
};
