import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Container className="footer py-2" fluid align="center">
      <Row className="links mb-2 justify-content-center">
        <Col xs="auto">
          <Link to="/terms">Terms of use</Link>
        </Col>
        <Col xs="auto">
          <Link to="/privacy">Privacy policy</Link>
        </Col>
        <Col xs="auto">
          <Link to="/contacts">Contact us</Link>
        </Col>
      </Row>
      <Row className="copyright" align="center">
        <Col>© {new Date().getFullYear()} Wannafake</Col>
      </Row>
    </Container>
  );
};
