import ReconnectingWebSocket from "reconnecting-websocket";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConnectionId } from "../features/account/accountSlice";
import config from "../config";
import {
  apiSlice,
  useUpdateConnectionMutation,
  useGetImagesQuery,
  useGetVideosQuery,
  useGetProfileQuery,
} from "../app/services/api";
import {
  states,
  setImgState,
  setImgErrorMessage,
  setShowAddImage,
  // getCurrentPage,
  getPageSize,
} from "../features/core/coreSlice";

export const useWebSocket = (auth) => {
  const wsEndpoint = config.apiGateway.URL;
  const { refetch: refetchImages } = useGetImagesQuery(undefined, {
    skip: !auth,
  });
  const { refetch: refetchVideos } = useGetVideosQuery(undefined, {
    skip: !auth,
  });
  // const { data: swaps } = useGetSwapsQuery(); //STALE??
  const { refetch: refetchProfile } = useGetProfileQuery(undefined, {
    skip: !auth,
  });

  // const currentPage = useSelector(getCurrentPage);
  const pageSize = useSelector(getPageSize);

  const dispatch = useDispatch();
  const [updateConnection] = useUpdateConnectionMutation();
  useEffect(() => {
    if (auth) {
      // create a websocket connection if authenticated
      const ws = new ReconnectingWebSocket(wsEndpoint);

      ws.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        switch (data.event) {
          case "pong":
            const connectionId = data.connectionId;
            dispatch(setConnectionId(connectionId));
            updateConnection({ connectionId });
            break;
          case "validate-img":
            switch (data.body.message) {
              case "validate-img-ok":
                refetchImages();
                dispatch(setShowAddImage(false));
                break;
              case "validate-img-fail":
                dispatch(setImgErrorMessage(data.body.error));
                dispatch(setImgState(states.ERROR));
                break;
              default:
                break;
            }
            break;
          case "validate-vid":
            // console.log("socket:", data.body);
            switch (data.body["event-type"]) {
              case "status-update":
                const { uuid: idStatus, status } = data.body;
                if (status === "FAIL") {
                  dispatch(
                    apiSlice.util.updateQueryData(
                      "getVideos",
                      undefined,
                      (draftVideos) => {
                        const ix = draftVideos.findIndex(
                          (obj) => obj.id === idStatus
                        ); // Returns -1 if not found
                        if (ix !== -1) {
                          draftVideos[ix].status = status;
                        }
                      }
                    )
                  );
                } else if (status === "OK") {
                  refetchVideos();
                }
                break;
              case "progress-update":
                const { uuid: idProgress, progress } = data.body;
                dispatch(
                  apiSlice.util.updateQueryData(
                    "getVideos",
                    undefined,
                    (draftVideos) => {
                      const ix = draftVideos.findIndex(
                        (obj) => obj.id === idProgress
                      ); // Returns -1 if not found
                      if (ix !== -1) {
                        draftVideos[ix].progress = 0.1 + 0.9 * progress;
                      }
                    }
                  )
                );
                break;
              case "thumb-ready":
                const { uuid: idThumb } = data.body;
                dispatch(
                  apiSlice.util.updateQueryData(
                    "getVideos",
                    undefined,
                    (draftVideos) => {
                      const ix = draftVideos.findIndex(
                        (obj) => obj.id === idThumb
                      ); // Returns -1 if not found
                      if (ix !== -1) {
                        draftVideos[ix].has_thumb = true;
                      }
                    }
                  )
                );
                break;
              default:
                break;
            }
            break;
          case "create-vid":
            switch (data.body["event-type"]) {
              case "status-update":
                const { uuid: idStatus, status } = data.body;
                if (status === "FAIL") {
                  refetchProfile();
                }

                // // Manual cache update only if cache already has entry for a new WIP swap
                // // This webhook event might arrive before a new swap entry is fetched
                // console.log("Swaps:", swaps, "Event:", data.body);
                // if (swaps) {
                dispatch(
                  apiSlice.util.updateQueryData(
                    "getSwaps",
                    { page: 1, pageSize: pageSize },
                    (draftSwaps) => {
                      const ix = draftSwaps.created_videos.findIndex(
                        (obj) => obj.id === idStatus
                      ); // Returns -1 if not found
                      if (ix !== -1) {
                        draftSwaps.created_videos[ix].status = status;
                      }
                    }
                  )
                );
                // }
                break;
              case "progress-update":
                const { uuid: idProgress, progress } = data.body;

                dispatch(
                  apiSlice.util.updateQueryData(
                    "getSwaps",
                    { page: 1, pageSize: pageSize },
                    (draftSwaps) => {
                      const ix = draftSwaps.created_videos.findIndex(
                        (obj) => obj.id === idProgress
                      ); // Returns -1 if not found
                      if (ix !== -1) {
                        draftSwaps.created_videos[ix].progress = progress;
                      }
                    }
                  )
                );
                // }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      });

      ws.addEventListener("open", () => {
        ws.send("ping"); // get connectionId upon connecting
      });

      return () => ws.close();
    }
  }, [
    auth,
    dispatch,
    wsEndpoint,
    refetchImages,
    refetchVideos,
    refetchProfile,
    updateConnection,
    pageSize,
  ]);
};
