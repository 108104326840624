import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/api";

const slice = createSlice({
  name: "auth",
  initialState: { isAuthenticated: false },
  reducers: {
    setIsAuthenticated(state, action) {
      state.isAuthenticated = action.payload;
    },
    logOut(state, action) {
      state.isAuthenticated = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.isAuthenticated = true;
        localStorage.setItem("accessToken", payload.access);
        localStorage.setItem("refreshToken", payload.refresh);
      }
    );
  },
});

// Actions
export const { setIsAuthenticated, logIn, logOut } = slice.actions;

// Selectors
export const getIsAuthenticated = (state) => state.auth.isAuthenticated;

export default slice.reducer;
