import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import TimeRange from "react-timeline-range-slider";
import {
  apiSlice,
  useUploadMediaMutation,
  useValidateVideoMutation,
  useS3UploadMutation,
  useEditMediaMutation,
} from "../../app/services/api";
import { states, getVidState, setVidState } from "./coreSlice";
import { AddVideoHelpModal } from "../../utils/HelpModals";
import * as Sentry from "@sentry/browser";

const sampleInterval = [
  new Date(1, 1, 1, 1, 0, 0, 0),
  new Date(1, 1, 1, 1, 0, 0, 999),
];

const minStep = 0.25; // Smallest trim allowed is 0.25 seconds

export const AddVideo = ({
  showAddVideo,
  handleCloseAddVideo,
  vidFile,
  vidURL,
  refetchVideos,
}) => {
  // Component state
  const state = useSelector(getVidState);
  const [trimStart, setTrimStart] = useState(0.0);
  const [trimEnd, setTrimEnd] = useState(1.0);
  const [vidDuration, setVidDuration] = useState(null);
  const [trimDuration, setTrimDuration] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(0.0);
  const [isAtTrimExtremity, setIsAtTrimExtremity] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const resetState = () => {
    setTrimStart(0.0);
    setTrimEnd(1.0);
    setVidDuration(null);
    setTrimDuration(null);
    setPlaying(false);
    setPlayed(0.0);
    setIsAtTrimExtremity(false);
  };

  useEffect(() => {
    if (vidDuration) {
      setTrimDuration((trimEnd - trimStart) * vidDuration);
    }
  }, [trimStart, trimEnd, vidDuration]);

  // Other hooks
  const dispatch = useDispatch();
  const player = useRef(null);

  // Help
  const handleCloseHelp = () => setShowHelp(false);
  const handleShowHelp = () => setShowHelp(true);

  // Mutations
  const [uploadMedia] = useUploadMediaMutation();
  const [validateVideo] = useValidateVideoMutation();
  const [s3Upload] = useS3UploadMutation();
  const [editMedia] = useEditMediaMutation();

  // Video setup
  const handlePlayStopTrimmedPreview = () => setPlaying(!playing);

  const onUploadClicked = async (e) => {
    try {
      dispatch(setVidState(states.WIP));

      // Grab file from local URL
      // let vidFile = await fetch(vidURL).then((r) => r.blob());

      // Ask for video presigned upload
      const { url, fields, uuid1, uuid2 } = await uploadMedia({
        type: vidFile.type,
      }).unwrap();

      try {
        dispatch(
          apiSlice.util.updateQueryData(
            "getVideos",
            undefined,
            (draftVideos) => {
              draftVideos.unshift({
                id: uuid2,
                status: "WIP",
                progress: 0.1,
                hidden: false,
                has_thumb: false,
                thumb_src: null,
                duration: 0.0,
                timestamp: "",
              });
              // draftVideos[0].status = "WIP";
            }
          )
        );

        resetState(); // Reset modal for next video
        handleCloseAddVideo(); // Close AddVideo modal

        // Upload to S3
        const s3Result = await s3Upload({ url, file: vidFile, fields });
        // Bubble up error if S3 upload failed - not throwing from inside s3Upload
        // to avoid RTKQ warning about unhandled exceptions inside custom queryFns
        if (s3Result.error) {
          throw new Error(s3Result.error);
        }

        // Validate video
        await validateVideo({
          uuid1,
          uuid2,
          vidDuration,
          trimStart,
          trimEnd,
        });

        // At this point video successfuly submitted for validation
        refetchVideos(); // Should now have one more WIP video
      } catch (err) {
        let args = { op: "fail", type: "vid", mediaId: uuid2 };
        editMedia(args);
        Sentry.captureException(err);
      }
    } catch (err) {
      dispatch(setVidState(states.ERROR)); // Show error message in modal
      Sentry.captureException(err);
    }
  };

  const renderCenterComponent = () => {
    let component;
    switch (state) {
      case states.READY:
      case states.WIP:
        component = (
          <div
            className="d-flex flex-column add-video-center-component"
            disabled={state === states.WIP}
          >
            <ReactPlayer
              ref={player}
              url={vidURL}
              controls={false}
              playing={playing}
              width="100%"
              height="100%"
              progressInterval={5}
              onDuration={(duration) => {
                setVidDuration(duration);
                setTrimDuration(duration);
              }}
              onProgress={(e) => {
                setPlayed(e.played);
                let secsTillEnd = Math.abs((trimEnd - played) * vidDuration);
                if (secsTillEnd < 0.1) {
                  setPlaying(false);
                  setIsAtTrimExtremity(true);
                  player.current.seekTo(trimEnd, "fraction");
                }
              }}
              onPlay={() => {
                if (isAtTrimExtremity) {
                  player.current.seekTo(trimStart, "fraction");
                  setPlayed(trimStart);
                  setIsAtTrimExtremity(false);
                }
                setPlaying(true);
              }}
              onPause={() => setPlaying(false)}
              playsinline
              config={{
                file: {
                  attributes: {
                    controlsList: "nofullscreen",
                  },
                },
              }}
            />
            {vidDuration && (
              <>
                <p className="my-0">
                  <b>Drag the ends of the blue bar to trim your video</b>
                </p>
                <div className="mb-6">
                  <TimeRange
                    ticksNumber={0}
                    style={null}
                    selectedInterval={sampleInterval}
                    timelineInterval={sampleInterval}
                    step={(minStep / vidDuration) * 1000}
                    mode={3}
                    onUpdateCallback={(e) => {
                      setPlaying(false);
                      const start = e.time[0].getMilliseconds() / 999;
                      const end = e.time[1].getMilliseconds() / 999;
                      if (start !== trimStart) {
                        // ie. if trimStart was updated
                        player.current.seekTo(start, "fraction");
                      } else if (end !== trimEnd) {
                        // ie. if trimEnd was updated
                        player.current.seekTo(end, "fraction");
                      }
                      setIsAtTrimExtremity(true);
                      setTrimStart(start);
                      setTrimEnd(end);
                    }}
                    onChangeCallback={(e) => {}}
                  />
                </div>
              </>
            )}
            <div className="d-flex flex-column align-items-center">
              <Button
                className="mb-3"
                variant="secondary"
                onClick={handlePlayStopTrimmedPreview}
              >
                {playing ? "Stop" : "Preview trimmed video"}
              </Button>
              {trimDuration ? (
                <>
                  <h4 className="my-0">
                    Selected duration: {Math.ceil(trimDuration)} secs
                  </h4>
                </>
              ) : (
                <h4 className="my-0">No video selected</h4>
              )}
            </div>
          </div>
        );
        break;
      // case states.ERROR:
      //   component = (
      //     <div>
      //       Error uploading video. Please try again later or contact support if
      //       this issue persists.
      //     </div>
      //   );
      //   break;
      default:
        component = (
          <div>Oh no! Something went wrong, please try again later.</div>
        );
        break;
    }
    return component;
  };

  const renderBottomButtons = () => {
    return state === states.READY || state === states.WIP ? (
      <Button
        className="my-0"
        onClick={onUploadClicked}
        disabled={state === states.WIP}
      >
        {state === states.READY ? (
          "Upload for free"
        ) : (
          <div>
            <Spinner as="span" size="sm" role="status" animation="border" />
            &nbsp;&nbsp;Preparing file
          </div>
        )}
      </Button>
    ) : (
      <Button
        className="my-0"
        variant="secondary"
        onClick={() => {
          handleCloseAddVideo();
          resetState();
        }}
      >
        Close
      </Button>
    );
  };

  return (
    <div className="add-media">
      <Modal
        show={showAddVideo}
        onHide={() => {
          handleCloseAddVideo();
          resetState();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="section-title">
              <h2>Add video</h2>
              <Button
                className="p-0 m-0 help-btn"
                variant="secondary"
                size="md"
                onClick={handleShowHelp}
              >
                <span>?</span>
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderCenterComponent()}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="add-media-bottom-btns">{renderBottomButtons()}</div>
        </Modal.Footer>
      </Modal>
      <AddVideoHelpModal
        showHelp={showHelp}
        handleCloseHelp={handleCloseHelp}
      />
    </div>
  );
};
