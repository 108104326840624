import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiHide, BiShow } from "react-icons/bi";
import { MdFileDownload, MdLink } from "react-icons/md";
import { useEditMediaMutation } from "../../app/services/api";
import { useSelector } from "react-redux";
import { getCurrentPage, getPageSize } from "../../features/core/coreSlice";
var classNames = require("classnames");

export const EditMediaButtons = ({ type, item }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const currentPage = useSelector(getCurrentPage);
  const pageSize = useSelector(getPageSize);

  const [editMedia] = useEditMediaMutation();
  const onClickBtn = (op) => {
    if (op === "delete") {
      setShowDeleteModal(false);
    }
    let args = { op: op, type, mediaId: item.id, currentPage, pageSize };
    editMedia(args);
  };

  const handleDeleteClose = () => setShowDeleteModal(false);
  const handleDeleteShow = () => setShowDeleteModal(true);
  const handleDownloadClose = () => setShowDownloadModal(false);
  const handleDownloadShow = () => setShowDownloadModal(true);
  const handleDownload = async (item) => {
    setIsDownloading(true);
    try {
      const response = await fetch(item.src);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item.id.slice(-8) + ".mp4");
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
    } finally {
      setIsDownloading(false);
      setShowDownloadModal(false);
    }
  };

  const itemClass = classNames({
    "d-flex justify-content-center flex-row align-items-center options-btns": true,
  });

  let days = item.days_left;

  return (
    <div className="d-flex flex-column justify-content-center">
      <div
        className={itemClass}
        style={{
          minWidth: "7.3rem", // To keep formatting incase the span showing deletion days is not displayed
        }}
      >
        <Modal show={showDeleteModal} onHide={handleDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteClose}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => onClickBtn("delete")}>
              Delete
              <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleDeleteClose}>
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={() => onClickBtn("delete")}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDownloadModal} onHide={handleDownloadClose}>
          <Modal.Header closeButton>
            <Modal.Title>Choose Download Method</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="download-option"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <a
                href={item.src}
                target="_blank"
                rel="noopener noreferrer"
                download={item.id.slice(-8) + ".mp4"}
                className="btn btn-primary mb-2"
                style={{ width: "auto" }}
                onClick={() => {
                  setShowDownloadModal(false);
                }}
              >
                <MdLink size={20} /> Download (most devices)
              </a>
              <p className="my-0 text-muted small">
                If download doesn't start automatically, right-click then select
                "save as...".
              </p>
              <Button
                variant="outline-primary"
                onClick={() => handleDownload(item)}
                disabled={item.status !== "OK" || isDownloading}
                className="mt-4 mb-2"
                style={{ width: "auto" }}
              >
                {isDownloading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <MdFileDownload size={20} />
                )}
                {isDownloading
                  ? " Downloading..."
                  : " Download (easiest for iOS)"}
              </Button>

              <p className="text-muted small my-0">
                Alternative option for devices where the first option is not
                supported.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDownloadClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {type === "swap" ? ( // Only offer download option to swaps
          <Button
            variant="primary"
            size="sm"
            onClick={handleDownloadShow}
            disabled={item.status !== "OK"}
          >
            <MdFileDownload size={18} />
          </Button>
        ) : null}

        {item.hidden ? (
          <Button
            variant="light"
            size="sm"
            onClick={() => onClickBtn("show")}
            disabled={type !== "img" && item.status !== "OK"}
          >
            <BiShow />
          </Button>
        ) : (
          <Button
            variant="secondary"
            size="sm"
            onClick={() => onClickBtn("hide")}
            disabled={type !== "img" && item.status !== "OK"}
          >
            <BiHide />
          </Button>
        )}
        <Button variant="danger" size="sm" onClick={handleDeleteShow}>
          <RiDeleteBinLine />
        </Button>
      </div>
      {days && (
        <span className="autodelete-text mt-2">
          Autodeletes in {days} day{Number(days) !== 1 && "s"}
        </span>
      )}
    </div>
  );
};
