import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { ImageThumbnail } from "./ImageThumbnail";
import { VideoThumbnail } from "./VideoThumbnail";
import { getSelectedImage, getSelectedVideo } from "./coreSlice";
import { useSelector } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IconContext } from "react-icons";
import config from "../../config";
import daysLeftFilter from "../../utils/misc";
var classNames = require("classnames");

export const MediaScroller = ({ type, items, onAddMediaClick }) => {
  const isFull = items?.length >= config.MAX_ITEMS;
  const numVisibleItems = items?.filter((i) => !i.hidden)?.length;
  const numHiddenItems = items?.filter((i) => i.hidden)?.length;

  const selectedVideoIsMultiFace =
    useSelector(getSelectedVideo)?.id_imgs.length > 1;

  const selectedImage = useSelector(getSelectedImage);

  const [notificationCount, setNotificationCount] = useState(null);
  useEffect(() => {
    items && setNotificationCount(daysLeftFilter(items));
  }, [items]);

  const editMediaButton = (
    <Button
      as={Link}
      hidden={!items || items.length === 0}
      className="btn-sm mx-2 notification-button"
      variant="secondary"
      to={`/edit-${type}`}
    >
      {type === "img" ? "Options" : "Options"}
      <span className="notification-badge">{notificationCount}</span>
    </Button>
  );

  var addMediaButtonClass = classNames({
    "d-flex h-100 flex-column align-items-center": true,
    "justify-content-center": type === "img",
    "justify-content-between": type !== "img",
  });

  const addMediaButton = (
    <div key="add" className={addMediaButtonClass}>
      <IconContext.Provider
        value={{
          size: "60px",
          className: `${isFull ? "disabled" : "enabled"} add-media ${type}`,
        }}
      >
        <div
          onClick={() => {
            if (!isFull) {
              onAddMediaClick();
            }
          }}
        >
          <IoIosAddCircleOutline />
        </div>
      </IconContext.Provider>
    </div>
  );

  const defaultItems = [
    addMediaButton,
    numVisibleItems > 0 ? null : (
      <Container key="msg" className="pt-3">
        {
          <i>
            {numHiddenItems > 0
              ? `You have ${numHiddenItems} hidden ${
                  type === "img" ? "face" : "video"
                }${numHiddenItems > 1 ? "s" : ""}`
              : type === "img"
              ? "No faces added yet"
              : "No videos added yet"}
          </i>
        }
      </Container>
    ),
  ].filter((v) => v);

  const scrollItem = (type, item, selectedImage) => {
    const content =
      type === "img" ? (
        <ImageThumbnail
          context="scroll"
          itemId={item.id}
          key={item.id}
          item={item}
        />
      ) : (
        <VideoThumbnail
          context="scroll"
          itemId={item.id}
          key={item.id}
          video={item}
          image={selectedImage}
        />
      );

    let willDeleteSoon = item.days_left <= 7;

    var scrollItemClass = classNames({
      "py-1 ps-1": true,
      "scroll-item-will-delete": willDeleteSoon,
    });

    return (
      <div key={item.id} className={scrollItemClass}>
        {content}
      </div>
    );
  };

  const scrollItems = defaultItems.concat(
    items
      .filter((item) => !item.hidden)
      .map((item) => scrollItem(type, item, selectedImage))
  );

  return (
    <Container className="media-scroller">
      <Row className="mb-1 align-items-end">
        <Col>
          <h5>
            {type === "img"
              ? "Put this face..."
              : selectedVideoIsMultiFace
              ? "...on this video..."
              : "...on this video"}
          </h5>
        </Col>

        <Col xs="auto" align="right" className="px-0 mx-0">
          {editMediaButton}
        </Col>
        {/* <Col xs="auto" align="right">
          {addMediaButton}
        </Col> */}
      </Row>
      {isFull ? (
        <Row>
          <Col>
            <span style={{ color: "red" }}>
              {type === "img" ? "Face" : "Video"} storage full. Delete old{" "}
              {type === "img" ? "faces" : "videos"} to add new ones.
            </span>
          </Col>
        </Row>
      ) : null}
      <Row>
        <ScrollMenu>{scrollItems}</ScrollMenu>
      </Row>
    </Container>
  );
};
