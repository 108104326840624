import { useState, useEffect, useRef } from "react";
import { Form, Button, Container, Spinner, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSignupMutation } from "../../app/services/api";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../../config";

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1, -1);

export const Signup = () => {
  const [signUp, { error: errorsSignUp, isLoading, isSuccess, isError }] =
    useSignupMutation();

  const recaptchaRef = useRef(null);
  const [response, setResponse] = useState(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is a required field"),
    password: Yup.string()
      .required("Password is a required field")
      .min(6, "Password is too short, must be 6 characters or more"),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password")], "Passwords must match"),
    terms: Yup.bool()
      .required()
      .oneOf(
        [true],
        "You must accept the terms and conditions to use Wannafake"
      ),
  });

  useEffect(() => {
    if (isError) {
      // Reset recaptcha and clear response if form submission error
      recaptchaRef.current.reset();
      setResponse(null);
    }
  }, [isError]);

  const onSubmit = async (values) => {
    await signUp({
      email: values.email,
      username: values.email,
      password: values.password,
      re_password: values.confirmPassword,
      response: response,
    });
  };

  const onRecaptchaChange = (value) => {
    setResponse(value);
  };

  return (
    <Container className="py-0 mt-3">
      {isSuccess ? (
        <Row className="mb-3">
          <h3>Thank you for registering</h3>
          <h6>Check your inbox to activate your account</h6>
        </Row>
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
            terms: false,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="my-3 justify-content-center">
                <Col md={5}>
                  <h2 className="my-0">Create an account</h2>
                </Col>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik01">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={
                      touched.email &&
                      (errors.email ||
                        errorsSignUp?.data?.email?.find((x) => x !== undefined))
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email ||
                      capitalize(
                        errorsSignUp?.data?.email?.find((x) => x !== undefined)
                      )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik02">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Choose a password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={
                      touched.password &&
                      (errors.password ||
                        errorsSignUp?.data?.password?.find(
                          (x) => x !== undefined
                        ))
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password ||
                      capitalize(
                        errorsSignUp?.data?.password?.find(
                          (x) => x !== undefined
                        )
                      )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik03">
                  <Form.Label>Confirm password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5">
                  <Form.Check
                    name="terms"
                    label={
                      <div>
                        {"I have read the "}
                        <Link to="/privacy">Privacy policy</Link>
                        {" and agree with the "}
                        <Link to="/terms">Terms and conditions</Link>
                      </div>
                    }
                    onChange={handleChange}
                    isInvalid={touched.terms && errors.terms}
                    feedback={errors.terms}
                    feedbackType="invalid"
                    id="validationFormik04"
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={5}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={config.recaptcha.siteKey}
                    onChange={onRecaptchaChange}
                  />
                </Col>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={5}>
                  <Button type="submit" disabled={!response}>
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                        role="status"
                      />
                    ) : (
                      "Create account"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};
