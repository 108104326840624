import { ListGroup } from "react-bootstrap";
import { EditMediaButtons } from "./EditMediaButtons";
import { VideoThumbnail } from "./VideoThumbnail";
var classNames = require("classnames");

export const EditMediaListItem = ({ type, item }) => {
  const itemClass = classNames({
    transparent: item.hidden,
    "edit-container": type === "img",
    "d-flex flex-row h-100": true,
  });
  let days = item.days_left;
  let willDeleteSoon = days <= 7;
  var editMediaListItemClass = classNames({
    "py-1": true,
    "px-1": type === "img",
    "px-0": type !== "img",
    "scroll-item-will-delete": willDeleteSoon,
  });

  const renderItem = () => {
    const media =
      type === "img" ? (
        <img className="face" alt="" src={item.src} />
      ) : (
        <VideoThumbnail
          className="disable-pointer"
          context="scroll"
          itemId={item.id}
          key={item.id}
          video={item}
          image={null}
          isEdit={true}
        />
      );
    return (
      <div className={itemClass}>
        <div className="me-3">{media}</div>
        <EditMediaButtons type={type} item={item} />
      </div>
    );
  };

  return (
    <ListGroup.Item className={editMediaListItemClass}>
      {renderItem()}
    </ListGroup.Item>
  );
};
