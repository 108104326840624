import { useState, useEffect, useRef } from "react";
import { Navbar, Container, Nav, Button, Image } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsAuthenticated } from "../auth/authSlice";
import { NavigationBarStatus } from "./NavigationBarStatus";

export const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const toggleExpanded = () => setExpanded(!expanded);
  const collapse = () => setExpanded(false);
  const wrapperRef = useRef(null);
  let history = useHistory();

  const location = useLocation();
  useEffect(() => {
    // Close menu if click outside
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <Navbar
      bg="light"
      expand={false}
      expanded={expanded}
      //className="justify-content-right"
      ref={wrapperRef}
    >
      <Container fluid className="px-0">
        <Navbar.Toggle
          onClick={toggleExpanded}
          aria-controls="basic-navbar-nav"
        />

        <Navbar.Brand as={Link} to="/">
          Wannafake
        </Navbar.Brand>

        {isAuthenticated ? (
          <>
            <NavigationBarStatus />

            <div className="shop-wrap">
              <button>
                <Image
                  src="/paypal_badge.jpg"
                  onClick={() => history.push("/shop")}
                />
              </button>
              <Button as={Link} variant="primary" to="/shop">
                Shop
              </Button>
            </div>
          </>
        ) : (
          <>
            <Button
              hidden={location?.pathname === "/login"}
              as={Link}
              to="/login"
            >
              Login
            </Button>
          </>
        )}

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className=".mr-auto">
            <Nav.Link onClick={collapse} as={Link} to="/">
              Home
            </Nav.Link>

            {isAuthenticated ? (
              <>
                <Nav.Link onClick={collapse} as={Link} to="/settings">
                  Settings
                </Nav.Link>
                <Nav.Link onClick={collapse} as={Link} to="/logout">
                  Sign out
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link onClick={collapse} as={Link} to="/login">
                  Login
                </Nav.Link>
                <Nav.Link onClick={collapse} as={Link} to="/signup">
                  Sign up
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
