import {
  Form,
  Button,
  Container,
  Spinner,
  Col,
  Row,
  Alert,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLoginMutation } from "../../app/services/api";
import * as Sentry from "@sentry/react";

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1, -1);

export const Login = () => {
  const [showFailedAuth, setShowFailedAuth] = useState(false);
  const [showNoActive, setShowNoActive] = useState(false);
  const [email, setEmail] = useState(""); // This state for logging to sentry only
  const [login, { error: errorsLogin, isLoading, isSuccess }] =
    useLoginMutation();

  // Show wrong password/email alert and log attempt to sentry
  useEffect(() => {
    if (errorsLogin?.data.code === "failed_authentication") {
      setShowFailedAuth(true);
      Sentry.captureMessage("failed login for email " + email);
    }
    if (errorsLogin?.data.code === "account_not_active") {
      setShowNoActive(true);
      Sentry.captureMessage("account not active for email " + email);
    }
  }, [errorsLogin, email]);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is a required field"),
    password: Yup.string().required("Password is a required field"),
  });

  const onSubmit = async (values) => {
    setEmail(values.email);
    await login({
      email: values.email,
      password: values.password,
    });
  };

  return (
    <Container className="py-0 mt-3">
      {isSuccess ? (
        <h3>Login successful!</h3>
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            email: "",
            password: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* <Row className="mt-3">
                <Col className="px-0">
                  <div className="alert alert-info" role="alert">
                    {"\u24D8"}&nbsp;&nbsp;
                    {
                      "Due to server maintenance scheduled for March 9th, Wannafake might become temporarily unavailable. Thank you for your understanding."
                    }
                  </div>
                </Col>
              </Row> */}
              <Row className="my-3 justify-content-center">
                <Col md={5}>
                  <h2 className="my-0">Login</h2>
                </Col>
              </Row>
              {showNoActive && (
                <Row className="justify-content-center">
                  <Col md={5}>
                    <Alert
                      variant="warning"
                      onClose={() => setShowNoActive(false)}
                      dismissible
                    >
                      You need to verify your account before using Wannafake.
                      Please check your inbox for your verification link.
                    </Alert>
                  </Col>
                </Row>
              )}
              {showFailedAuth && (
                <Row className="justify-content-center">
                  <Col md={5}>
                    <Alert
                      variant="danger"
                      onClose={() => setShowFailedAuth(false)}
                      dismissible
                    >
                      Wrong email or password.
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik01">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={
                      touched.email &&
                      (errors.email ||
                        errorsLogin?.data.email?.find((x) => x !== undefined))
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email ||
                      capitalize(
                        errorsLogin?.data.email?.find((x) => x !== undefined)
                      )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Form.Group as={Col} md="5" controlId="validationFormik02">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={
                      touched.password &&
                      (errors.password ||
                        errorsLogin?.data.password?.find(
                          (x) => x !== undefined
                        ))
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password ||
                      capitalize(
                        errorsLogin?.data.password?.find((x) => x !== undefined)
                      )}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={5}>
                  <Button type="submit">
                    {isLoading ? (
                      <Spinner
                        animation="border"
                        as="span"
                        size="sm"
                        role="status"
                      />
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Col>
              </Row>
              <Row className="mb-1 justify-content-center">
                <Col md={5} xs="auto">
                  <Link to="/reset">Forgot password?</Link>
                </Col>
              </Row>
              <Row className="mb-3 justify-content-center">
                <Col md={5} xs="auto">
                  <Link to="/signup">Don't have an account? Sign up</Link>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};
