import React, { useState } from "react";
import {
  Button,
  Container,
  Card,
  Row,
  Col,
  Spinner,
  CloseButton,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import {
  useCheckoutMutation,
  useOrderMutation,
  useConfirmMutation,
  useGetProfileQuery,
} from "../../app/services/api";

export const Shop = () => {
  const { push } = useHistory();
  const { refetch: refetchProfile } = useGetProfileQuery();
  const [checkout, { isLoadingCheckout }] = useCheckoutMutation();
  const [order] = useOrderMutation();
  const [confirm] = useConfirmMutation();
  const [product, setProduct] = useState("");
  const [showPaypalError, setShowPaypalError] = useState(false);
  const [{ isResolved }] = usePayPalScriptReducer();

  const buyProduct = async (e, product) => {
    e.preventDefault();
    setProduct(product);
    const { data: checkoutLink } = await checkout({ product });
    window.location.href = checkoutLink;
  };
  const createOrder = async (product) => {
    setProduct(product);
    const { data } = await order({ product });
    if (data) {
      // If no error from server
      return data["id"];
    } else {
      setShowPaypalError(true);
      return "XXXXXXXXXXXXXXXXX"; // For aborting onApprove later, which must be called with a non-null 19-char id str else validation exception is throw elsewhere before it is even called
    }
  };
  const onApprove = async (data) => {
    if (data.orderID && data.orderID !== "XXXXXXXXXXXXXXXXX") {
      const { data: approveData } = await confirm({ id: data.orderID });
      if (approveData) {
        // If no error from server
        refetchProfile(); // To refresh user balance
        push("/success");
        return approveData;
      } else {
        setShowPaypalError(true);
      }
    }
  };

  const paypalError = (
    <div
      className="alert alert-warning"
      role="alert"
      style={{ maxWidth: "70%", minWidth: "18rem" }}
    >
      <div className="d-flex justify-content-between">
        <span>
          Unable to process your Paypal order. If this issue persists please
          contact our support team.
        </span>
        <CloseButton onClick={() => setShowPaypalError(false)} />
      </div>
    </div>
  );

  const render = false ? (
    <div className="loading-screen">
      <Spinner animation="border" variant="secondary" />
      <span className="my-3">Loading...</span>
    </div>
  ) : (
    <>
      <Container fluid="md" className="px-3 py-0">
        <Row className="my-3">
          <h2 className="my-0">Shopping</h2>
        </Row>
        <Row className="my-3">
          <Col xs="auto">
            <Button variant="secondary" onClick={() => push("/home")}>
              Back
            </Button>
          </Col>
        </Row>
        <Row className="">
          <Col align="center">{showPaypalError && paypalError}</Col>
        </Row>
        <Row>
          <Col align="center" xs={12}>
            <div className="shop-card mx-0 large">
              <Card>
                <Card.Body>
                  <Card.Title>Large recharge</Card.Title>
                  <Card.Title>
                    <i>$69.99</i>
                  </Card.Title>
                  <Card.Text>
                    <span className="duration">
                      <b>+3600 seconds</b>
                    </span>{" "}
                    (1 hour)
                    <br />
                    For face swap pros
                    <br />
                  </Card.Text>
                  <div className="shop-btns-container">
                    <Button
                      className="stripe-btn"
                      variant="primary"
                      onClick={(e) => buyProduct(e, "Large")}
                    >
                      {isLoadingCheckout && product === "Large" ? (
                        <Spinner
                          animation="border"
                          as="span"
                          size="sm"
                          role="status"
                        />
                      ) : (
                        "Proceed to checkout"
                      )}
                    </Button>
                    <span>
                      <i>or use</i>
                    </span>
                    <PayPalButtons
                      style={{
                        layout: "horizontal",
                        tagline: false,
                        label: "paypal",
                      }}
                      disabled={!isResolved}
                      createOrder={() => createOrder("Large")}
                      onApprove={onApprove}
                      onError={() => setShowPaypalError(true)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col align="center" xs={12}>
            <div className="shop-card mx-0 medium">
              <Card>
                <Card.Body>
                  <Card.Title>Medium recharge</Card.Title>
                  <Card.Title>
                    <i>$12.99</i>
                  </Card.Title>
                  <Card.Text>
                    <span className="duration">
                      <b>+600 seconds</b>
                    </span>{" "}
                    (10 minutes)
                    <br />
                    For face swap enthusiasts
                    <br />
                  </Card.Text>
                  <div className="shop-btns-container">
                    <Button
                      className="stripe-btn"
                      variant="primary"
                      onClick={(e) => buyProduct(e, "Medium")}
                    >
                      {isLoadingCheckout && product === "Medium" ? (
                        <Spinner
                          animation="border"
                          as="span"
                          size="sm"
                          role="status"
                        />
                      ) : (
                        "Proceed to checkout"
                      )}
                    </Button>
                    <span>
                      <i>or use</i>
                    </span>
                    <PayPalButtons
                      style={{
                        layout: "horizontal",
                        tagline: false,
                        label: "paypal",
                      }}
                      disabled={!isResolved}
                      createOrder={() => createOrder("Medium")}
                      onApprove={onApprove}
                      onError={() => setShowPaypalError(true)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col align="center" xs={12}>
            <div className="shop-card mx-0 small">
              <Card>
                <Card.Body>
                  <Card.Title>Small recharge</Card.Title>
                  <Card.Title>
                    <i>$2.99</i>
                  </Card.Title>
                  <Card.Text>
                    <span className="duration">
                      <b>+120 seconds</b>
                    </span>{" "}
                    (2 minutes)
                    <br />
                    For face swap beginners
                    <br />
                  </Card.Text>
                  <div className="shop-btns-container">
                    <Button
                      className="stripe-btn"
                      variant="primary"
                      onClick={(e) => buyProduct(e, "Small")}
                    >
                      {isLoadingCheckout && product === "Small" ? (
                        <Spinner
                          animation="border"
                          as="span"
                          size="sm"
                          role="status"
                        />
                      ) : (
                        "Proceed to checkout"
                      )}
                    </Button>
                    <span>
                      <i>or use</i>
                    </span>
                    <PayPalButtons
                      style={{
                        layout: "horizontal",
                        tagline: false,
                        label: "paypal",
                      }}
                      disabled={!isResolved}
                      createOrder={() => createOrder("Small")}
                      onApprove={onApprove}
                      onError={() => setShowPaypalError(true)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );

  return render;
};
