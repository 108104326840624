import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedVideo, setSelectedVideo } from "./coreSlice";
import { ProgressBar, Spinner, Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { useEditMediaMutation } from "../../app/services/api";
var classNames = require("classnames");

export const VideoThumbnail = ({ video, image, isEdit = false }) => {
  const [vidHasLoaded, setVidHasLoaded] = useState(false);
  const [imgHasLoaded, setImgHasLoaded] = useState(false);
  const dispatch = useDispatch();
  const [editMedia] = useEditMediaMutation();
  const deleteItem = (id) => {
    let args = { op: "delete", type: "vid", mediaId: id };
    editMedia(args);
  };
  const selectedVideo = useSelector(getSelectedVideo);
  const isRenderedSelected = video?.id === selectedVideo?.id;

  const videoContainerClassNames = classNames({
    "video-thumbnail-container": true,
    "converter-video-item": true,
    "scroll-item-selected": isRenderedSelected && !isEdit,
    "scroll-item": !isRenderedSelected || isEdit,
  });

  const renderImage = () =>
    image && video?.status === "OK" ? (
      <img
        className="face"
        alt=""
        src={image.src}
        style={{ display: vidHasLoaded ? "block" : "none" }}
        onLoad={() => setImgHasLoaded(true)}
      />
    ) : null;

  const renderVideo = () => {
    if (video) {
      let imgProps = {
        className: "video-thumb",
        src: video.thumb_src,
        style: { display: vidHasLoaded ? "block" : "none" },
        onLoad: () => setVidHasLoaded(true),
      };

      if (video.status === "OK") {
        imgProps["onClick"] = () => dispatch(setSelectedVideo(video));
      } else if (video.status === "WIP") {
        imgProps["style"] = { ...imgProps.style, opacity: 0.6 };
      } else if (video.status === "FAIL") {
        imgProps["style"] = { ...imgProps.style, opacity: 0.6 };
      }

      return (
        <div className="h-100 d-flex flex-column justify-content-center">
          {video.has_thumb && <img alt="" {...imgProps} />}
          {!(vidHasLoaded && imgHasLoaded) && video.status !== "FAIL" && (
            <Spinner
              style={{
                display: vidHasLoaded ? "none" : "block",
              }}
              animation="border"
              variant="secondary"
            />
          )}
          {video.status === "WIP" && (
            <ProgressBar
              animated
              // style={{
              //   display: vidHasLoaded ? "flex" : "none",
              // }}
              now={video.progress * 98 || 9.8} // Show 9.8% of bar, which corresponds to progress 0 or undefined
            />
          )}
          {video.status === "FAIL" && (
            <div>
              <div className="dismiss-text">
                <b>Upload failed</b>
                <br />
                Please try again later.
              </div>
              <Button
                variant="danger"
                size="sm"
                onClick={() => deleteItem(video.id)}
              >
                <IoMdClose />
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={videoContainerClassNames}
      onClick={
        !isEdit && image && video?.status === "OK"
          ? () => dispatch(setSelectedVideo(video))
          : null
      }
    >
      {renderImage()}
      {renderVideo()}
    </div>
  );
};
