import { Container, Button, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export const Expired = () => {
  const { push } = useHistory();

  return (
    <Container>
      <Row className="my-3">
        <Col>
          <h6 className="my-0">
            Your session has expired. Please log in again.
          </h6>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Button
            variant="primary"
            type="submit"
            onClick={(e) => {
              push("/login");
            }}
          >
            Login
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
