import { Container, Row, Col, Button, Card, ListGroup } from "react-bootstrap";
import { FaRegSmileWink, FaRegSmileBeam, FaSearchDollar } from "react-icons/fa";
import { HiScissors } from "react-icons/hi";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { RiVideoLine, RiVideoFill } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

export const Landing = () => {
  const keyItem = ({ icon, title, body }) => (
    <ListGroup.Item className="key-item">
      <div className="heading">
        <div className="icon">{icon}</div>
        <h5 className="title">{title}</h5>
      </div>
      <p className="body">{body}</p>
    </ListGroup.Item>
  );

  return (
    <Container className="mx-0 px-0 landing">
      {/* <div className="mt-3 alert alert-primary" role="alert">
        Wannafake is undergoing maintenance and is temporarily unavailable
      </div>
      <div className="alert alert-primary" role="alert">
        Thank you for your understanding, please check back later
      </div> */}
      <Row className="justify-content-center px-3 py-3 section-1">
        <Col align="center" xs={8} sm={6} md={5} lg={4}>
          <h1>Swap faces in videos using one photo</h1>
          <h5>Easily create fun and original videos</h5>
        </Col>
      </Row>
      <Row className="video-row justify-content-center">
        <Col sm={6} md={5} lg={4} className="mx-0 px-0">
          <Link to="/signup">
            <div className="player-wrapper">
              <div className="d-flex justify-content-center">
                <ReactPlayer
                  className="react-player fixed-bottom"
                  url="landingVideo.mp4"
                  width="100%"
                  height="100%"
                  volume={0}
                  muted
                  loop
                  playing
                  controls={false}
                  playsinline
                />
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row className="px-3 pt-3">
        <Col align="center">
          <h1>How it works</h1>
        </Col>
      </Row>
      <Row className="pb-3 justify-content-center">
        <Col xs="auto" align="center" className="mx-3 mt-3">
          <Card id="step-1" className="gradient-border">
            <Card.Body>
              <Card.Title>1. Upload your photos and videos for free</Card.Title>
              <div className="how-to-icon my-3">
                <FaRegSmileWink />
                <FaRegSmileBeam />
                <RiVideoLine />
                <RiVideoFill />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs="auto" align="center" className="mx-3 mt-3">
          <Card id="step-2">
            <Card.Body>
              <Card.Title>
                2. Combine photos and videos to create new videos
              </Card.Title>
              <div className="how-to-icon  my-3">
                <FaRegSmileWink />
                <AiOutlinePlus />
                <RiVideoLine />
              </div>
              <div className="how-to-icon  mb-3">
                <FaRegSmileBeam />
                <AiOutlinePlus />
                <RiVideoFill />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="px-3 pt-3 pay-row justify-content-center">
        <Col align="center" xs="auto">
          <h1>A simple face swap tool</h1>
          <ListGroup className="key-points-list" variant="flush">
            {keyItem({
              icon: <FaSearchDollar />,
              title: "No subscriptions, pay as you go",
              body: "Buy seconds and spend them whenever you want",
            })}
            {keyItem({
              icon: <HiScissors />,
              title: "Built in video clipping",
              body: "Easily trim your videos and pay only for the clipped part",
            })}
            {keyItem({
              icon: <BsFillLightningChargeFill />,
              title: "Create multiple videos at the same time",
              body: "Videos created fast and in parallel",
            })}
          </ListGroup>
        </Col>
      </Row>
      <Row className="px-3 py-3 cta-row justify-content-center">
        <Col align="center" xs="auto">
          <h1>Try 15 seconds for free</h1>
          <Button as={Link} variant="primary" to="/signup">
            Create free account
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
