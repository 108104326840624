import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getSelectedImage, setSelectedImage } from "./coreSlice";
var classNames = require("classnames");

export const ImageThumbnail = ({ item: renderedImage }) => {
  const dispatch = useDispatch();
  const [imgHasLoaded, setImgHasLoaded] = useState(false);
  const selectedImage = useSelector(getSelectedImage);
  const isRenderedSelected = renderedImage.id === selectedImage?.id;

  var imageClass = classNames({
    "scroll-item-selected": isRenderedSelected,
    "scroll-item": !isRenderedSelected,
    "scroll-item-image": true,
  });

  const onImageClick = () => {
    dispatch(setSelectedImage(renderedImage));
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-center">
      <div className={imageClass}>
        <div>
          <img
            className="face"
            style={{
              display: imgHasLoaded ? "block" : "none",
            }}
            alt=""
            src={renderedImage.src}
            onClick={onImageClick}
            onLoad={() => setImgHasLoaded(true)}
          />
        </div>
        <Spinner
          style={{
            display: imgHasLoaded ? "none" : "block",
            width: "52px",
            height: "52px",
          }}
          animation="border"
          variant="secondary"
          size="lg"
        />
      </div>
    </div>
  );
};
