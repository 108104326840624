import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Terms = () => {
  return (
    <Container fluid className="mt-3">
      <div>
        <h1>
          <span>Terms of Use</span>
        </h1>
        <p>
          <span>Updated November 14th, 2023</span>
        </p>
        <p>&nbsp;</p>
        <p>
          <span>
            Please read these Terms of Use (these "Terms") carefully as they
            govern your use of (which includes access to) Wannafake’s services
            for generating new media content, including our website (located at
            wannafake.com) that incorporates or links to these Terms
            (collectively, "Wannafake").
          </span>
        </p>
        <p>
          <span>
            Use of Wannafake may be subject to additional terms and conditions
            presented by Wannafake, which are hereby incorporated by this
            reference into these Terms.
          </span>
        </p>
        <p>
          <span>
            By signing up for, or otherwise using, Wannafake, you agree to these
            Terms. If you do not agree to these Terms, then you must not use
            Wannafake.
          </span>
        </p>
        <p>
          <span>
            BE AWARE THAT SECTION 14 OF THE TERMS, BELOW, CONTAINS PROVISIONS
            GOVERNING HOW DISPUTES THAT YOU AND WANNAFAKE HAVE AGAINST EACH
            OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY DISPUTES THAT
            AROSE OR WERE ASSERTED PRIOR TO THE EFFECTIVE DATE OF THESE TERMS.
            IN PARTICULAR, IT CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH
            LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO
            BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE ARBITRATION
            AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE DISPUTES OR
            CLAIMS AND SEEK RELIEVE AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A
            PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
            PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO PURSUE DISPUTES OR
            CLAIMS AND SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL.
          </span>
        </p>
        <p>
          <span>
            ANY DISPUTE, CLAIM OR REQUEST FOR RELIEF RELATING IN ANY WAY TO YOUR
            USE OF WANNAFAKE SHALL BE DETERMINED BY ONE ARBITRATOR WHO WILL BE
            CHOSEN IN ACCORDANCE WITH THE ARBITRATION AND INTERNAL RULES OF THE
            EUROPEAN COURT OF ARBITRATION BEING PART OF THE EUROPEAN CENTRE OF
            ARBITRATION HAVING ITS SEAT IN STRASBOURG, AND WHICH ARE IN FORCE AT
            THE TIME THE APPLICATION FOR ARBITRATION IS FILED, AND OF WHICH
            ADOPTION OF THIS CLAUSE CONSTITUTES ACCEPTANCE. THE SEAT OF
            ARBITRATION SHALL BE STRASBOURG. THE LANGUAGE OF THE PROCEEDINGS
            SHALL BE FRENCH. APPLICABLE RULES OF SUBSTANTIVE LAW SHALL BE THE
            LAW OF FRANCE. THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE
            INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED FROM THE TERMS. If
            you do not agree to these Terms, including the mandatory arbitration
            provision and class action waiver in Section 14, do not access or
            use Wannafake.
          </span>
        </p>
        <p>
          <span>
            PLEASE NOTE THAT THESE TERMS ARE SUBJECT TO CHANGE BY WANNAFAKE AT
            ITS SOLE DISCRETION AT ANY TIME. A new copy of the Terms will be
            made available at our website whenever there are new changes. We
            will also update the “Updated” date at the top of the Terms. Any
            changes to the Terms will be effective immediately for new users of
            Wannafake and will be effective thirty (30) days after posting
            notice of such changes on the website for existing users, provided
            that any material changes shall be effective for existing users upon
            the earlier of thirty (30) days after posting notice of such changes
            on the website or thirty (30) days after dispatch of the
            notification email of such changes. We may require you to provide
            consent to the updated Terms in a specified manner before further
            use of Wannafake is permitted. If you do not agree to any change(s)
            after receiving a notice of such change(s), you shall stop using
            Wannafake. Otherwise, your continued use of Wannafake constitutes
            your acceptance of such changes. PLEASE CHECK WANNAFAKE REGULARLY TO
            VIEW THE THEN-CURRENT TERMS.
          </span>
        </p>
        <p>
          <span>
            If you have any questions about these Terms, please contact us at
            legal@wannafake.com
          </span>
        </p>
        <p>&nbsp;</p>
        <h4>
          <span>1. ELIGIBILITY</span>
        </h4>
        <p>
          <span>
            You must be at least 13 years of age to access or use Wannafake. If
            you are under 18 years of age (or the age of legal majority where
            you live), you may only access or use Wannafake under the
            supervision of a parent or legal guardian who agrees to be bound by
            these Terms. If you are a parent or legal guardian of a user under
            the age of 18 (or the age of legal majority), you agree to be fully
            responsible for the acts or omissions of such user in connection
            with Wannafake. If you are accessing or using Wannafake on behalf of
            another person or entity, you represent that you are authorized to
            accept these Terms on that person or entity’s behalf and that the
            person or entity agrees to be responsible to us if you or the other
            person or entity violates these Terms.
          </span>
        </p>
        <h4>
          <span>2. USER ACCOUNTS AND ACCOUNT SECURITY</span>
        </h4>
        <p>
          <span>
            You must promptly notify us if you discover or suspect that someone
            has accessed your account without your permission. If you permit
            others to use your account credentials, you are responsible for the
            activities of such users that occur in connection with your account.
          </span>
        </p>
        <h4>
          <span>3. PRIVACY</span>
        </h4>
        <p>
          <span>
            Please refer to our <Link to="/privacy">Privacy Policy</Link> for
            information about how we collect, use and disclose information about
            you.
          </span>
        </p>
        <h4>
          <span>4. USER CONTENT</span>
        </h4>
        <p>
          <span>
            Wannafake may allow you and other users to upload, create and store
            content, including photos, videos, and other materials
            (collectively, “User Content”). Subject to these Terms and the
            Privacy Policy, you retain all rights in and to your User Content,
            as between you and Wannafake. Further, Wannafake does not claim
            ownership of any User Content that you create or share on or through
            Wannafake. You grant Wannafake a nonexclusive, royalty-free,
            worldwide, fully paid license to use, store, reproduce, modify,
            adapt, create derivative works from, distribute, perform and display
            your User Content during the term of these Terms SOLELY TO ALLOW
            YOUR USE OF WANNAFAKE.
          </span>
        </p>
        <p>
          <span>
            You represent and warrant that: (i) you own or otherwise have the
            right to use the User Content modified by you on or through
            Wannafake in accordance with the rights and licenses set forth in
            these Terms; (ii) you agree to pay for all royalties, fees, and any
            other monies owed by reason of User Content you create on or through
            Wannafake; and (iii) you have the legal right and capacity to enter
            into these Terms in your jurisdiction.
          </span>
        </p>
        <p>
          <span>
            You may not create, post, store or share any User Content that
            violates these Terms or for which you do not have all the rights
            necessary to grant us the license described above. Although we have
            no obligation to screen, edit or monitor User Content, we may delete
            or remove User Content at any time and for any reason.
          </span>
        </p>
        <p>
          <span>
            Wannafake is not a backup service and you agree that you will not
            rely on Wannafake for the purposes of User Content backup or
            storage. Wannafake will not be liable to you for any modification,
            suspension, or discontinuation of Wannafake, or the loss of any User
            Content.
          </span>
        </p>
        <h4>
          <span>5. PROHIBITED CONDUCT AND CONTENT</span>
        </h4>
        <p>
          <span>
            You will not violate any applicable law, contract, intellectual
            property or other third-party right or commit a tort, and you are
            solely responsible for your conduct while accessing or using
            Wannafake. You will not:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "3rem" }}
        >
          <li>
            <p>
              <span>
                Engage in any harassing, threatening, intimidating, predatory or
                stalking conduct;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Use or attempt to use another user’s account without
                authorization from that user and Wannafake;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Use Wannafake in any manner that could interfere with, disrupt,
                negatively affect or inhibit other users from fully enjoying
                Wannafake or that could damage, disable, overburden or impair
                the functioning of Wannafake in any manner;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Reverse engineer any aspect of Wannafake or do anything that
                might discover source code or bypass or circumvent measures
                employed to prevent or limit access to any part of Wannafake;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Attempt to circumvent any content-filtering techniques we employ
                or attempt to access any feature or area of Wannafake that you
                are not authorized to access;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Develop or use any third-party applications that interact with
                Wannafake without our prior written consent, including any
                scripts designed to scrape or extract data from Wannafake;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Use Wannafake for any illegal or unauthorized purpose, or engage
                in, encourage or promote any activity that violates these Terms.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <span>
            You may also only upload or otherwise create User Content that is
            non-confidential and you have all necessary rights to disclose. You
            may not create, upload, post, store or share any User Content that:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "3rem" }}
        >
          <li>
            <p>
              <span>
                Is unlawful, libelous, defamatory, obscene, pornographic,
                indecent, lewd, suggestive, harassing, threatening, invasive of
                privacy or publicity rights, abusive, inflammatory or
                fraudulent;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Would constitute, encourage or provide instructions for a
                criminal offense, violate the rights of any party or otherwise
                create liability or violate any local, state, national or
                international law;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                May infringe any patent, trademark, trade secret, copyright or
                other intellectual or proprietary right of any party;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Contains or depicts any statements, remarks or claims that do
                not reflect your honest views and experiences;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Impersonates, or misrepresents your affiliation with, any person
                or entity;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Contains any unsolicited promotions, political campaigning,
                advertising or solicitations;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Contains any private or personal information of a third party
                without such third party’s consent;
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Contains any viruses, corrupted data or other harmful,
                disruptive or destructive files or content; or
              </span>
            </p>
          </li>
          <li>
            <p>
              <span>
                Is, in our sole judgment, objectionable or that restricts or
                inhibits any other person from using or enjoying Wannafake, or
                that may expose Wannafake or others to any harm or liability of
                any type.
              </span>
            </p>
          </li>
        </ul>
        <h4>
          <span>6. LIMITED LICENSE; COPYRIGHT AND TRADEMARK</span>
        </h4>
        <p>
          <span>
            Wannafake and the text, graphics, images, photographs, videos,
            illustrations, trademarks, trade names, page headers, button icons,
            scripts, service marks, logos, slogans, and other content contained
            therein (collectively, the “Wannafake Content”) are owned by or
            licensed to Wannafake and are protected by law. Except as explicitly
            stated in these Terms, Wannafake and our licensors reserve all
            rights in and to Wannafake and the Wannafake Content. You are hereby
            granted a limited, nonexclusive, nontransferable, non-sublicensable,
            revocable license to access and use Wannafake and Wannafake Content
            for your own personal use; however, such license is subject to these
            Terms and does not include any right to: (a) sell, resell or
            commercially use Wannafake or Wannafake Content; (b) copy,
            reproduce, distribute, publicly perform or publicly display
            Wannafake Content, except as expressly permitted by us or our
            licensors; (c) modify Wannafake Content, remove any proprietary
            rights notices or markings, or otherwise make any derivative uses of
            Wannafake or Wannafake Content, except as expressly set forth in
            these Terms; (d) use any data mining, robots or similar data
            gathering or extraction methods; or (e) use Wannafake or Wannafake
            Content other than as expressly provided in these Terms. Any use of
            Wannafake or Wannafake Content other than as specifically authorized
            herein, without our prior written permission, is strictly prohibited
            and will terminate the license granted under these Terms. You will
            not remove, alter or conceal any copyright, trademark, service mark
            or other proprietary rights notices incorporated in or accompanying
            Wannafake Content.
          </span>
        </p>
        <h4>
          <span>7. FEEDBACK</span>
        </h4>
        <p>
          <span>
            Any questions, comments, suggestions, ideas, original or creative
            materials or other information you submit about Wannafake
            (collectively, “Feedback”), is non-confidential and we have no
            obligations (including without limitation obligations of
            confidentiality) with respect to such Feedback. You hereby grant to
            Wannafake a fully paid, royalty-free, perpetual, irrevocable,
            worldwide, non-exclusive, and fully sublicensable right and license
            to use, reproduce, perform, display, distribute, adapt, modify,
            re-format, create derivative works of, and otherwise commercially or
            non-commercially exploit in any manner, any and all Feedback, and to
            sublicense the foregoing rights, in connection with the operation
            and maintenance of Wannafake and/or Wannafake’s business.
          </span>
        </p>
        <h4>
          <span>8. COPYRIGHT COMPLAINTS</span>
        </h4>
        <p>
          <span>
            We have a policy of limiting access to Wannafake and terminating the
            accounts of users who repeatedly infringe the intellectual property
            copyright rights of others upon prompt notification to us by the
            copyright owner or the copyright owner’s legal agent. Without
            limiting the foregoing, if you believe that your work has been
            copied and posted on or through Wannafake in a way that constitutes
            copyright infringement, please contact us at legal@wannafake.com
            with the following information: (a) an electronic or physical
            signature of the person authorized to act on behalf of the owner of
            the copyright interest; (b) a description and copy of the
            copyrighted work that you claim has been infringed; (c) the email of
            the Wannafake offendant user you claim is infringing; (d) your
            address, telephone number and e-mail address; (e) a written
            statement by you that you have a good faith belief that the disputed
            use is not authorized by the copyright owner, its agent or the law;
            and (f) a statement by you, made under penalty of perjury, that the
            above information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner’s
            behalf.&nbsp;
          </span>
        </p>
        <h4>
          <p>
            <span>9. INDEMNIFICATION</span>
          </p>
        </h4>
        <p>
          <span>
            To the fullest extent permitted by applicable law, you will
            indemnify, defend, and hold harmless Wannafake and each of our
            respective officers, directors, agents, partners and employees
            (individually and collectively, the “Wannafake Parties”) from and
            against any loss, liability, claim, demand, damages, expenses or
            costs (“Claims”) arising out of or related to (a) your access to or
            use of Wannafake; (b) your User Content or Feedback; (c) your
            violation of these Terms; (d) your violation, misappropriation or
            infringement of any rights of another (including intellectual
            property rights or privacy rights); or (e) your conduct in
            connection with Wannafake. You agree to promptly notify Wannafake
            Parties of any third party Claims, cooperate with Wannafake Parties
            in defending such Claims and pay all fees, costs and expenses
            associated with defending such Claims (including, but not limited
            to, attorneys’ fees). You also agree that the Wannafake Parties will
            have control of the defense or settlement of any third party Claims.
            This indemnity is in addition to, and not in lieu of, any other
            indemnities set forth in a written agreement between you and
            Wannafake or the other Wannafake Parties.
          </span>
        </p>
        <h4>
          <span>10. DISCLAIMERS</span>
        </h4>
        <p>
          <span>
            We do not control, endorse or take responsibility for any User
            Content or third-party content available on or linked to by
            Wannafake.
          </span>
        </p>
        <p>
          <span>
            YOUR USE OF OUR SERVICES IS AT YOUR SOLE RISK. OUR SERVICES ARE
            PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND,
            EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE, AND NON-INFRINGEMENT. In addition, Wannafake does not
            represent or warrant that Wannafake functionality is accurate,
            complete, reliable, current or error-free. While Wannafake attempts
            to make your access to and use of Wannafake safe, we cannot and do
            not represent or warrant that Wannafake or servers are free of
            viruses or other harmful components. You assume the entire risk as
            to the quality and performance of Wannafake.
          </span>
        </p>
        <h4>
          <span>11. LIMITATION OF LIABILITY</span>
        </h4>
        <p>
          <span>
            WANNAFAKE AND THE OTHER WANNAFAKE PARTIES WILL NOT BE LIABLE TO YOU
            UNDER ANY THEORY OF LIABILITY—WHETHER BASED IN CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY, WARRANTY, OR OTHERWISE—FOR ANY
            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, PUNITIVE OR SPECIAL
            DAMAGES OR LOST PROFITS, EVEN IF WANNAFAKE OR THE OTHER WANNAFAKE
            PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </span>
        </p>
        <p>
          <span>
            THE TOTAL LIABILITY OF WANNAFAKE AND THE OTHER WANNAFAKE PARTIES,
            FOR ANY CLAIM ARISING OUT OF OR RELATING TO THIS AGREEMENT OR OUR
            SERVICES, REGARDLESS OF THE FORM OF THE ACTION, IS LIMITED TO THE
            AMOUNT PAID, IF ANY, BY YOU TO ACCESS OR USE OUR SERVICES.
          </span>
        </p>
        <p>
          <span>
            The limitations set forth in this section will not limit or exclude
            liability for the gross negligence, fraud or intentional misconduct
            of Wannafake or the other Wannafake Parties or for any other matters
            in which liability cannot be excluded or limited under applicable
            law. Additionally, some jurisdictions do not allow the exclusion or
            limitation of incidental or consequential damages, so the above
            limitations or exclusions may not apply to you.
          </span>
        </p>
        <h4>
          <span>12. RELEASE</span>
        </h4>
        <p>
          <span>
            To the fullest extent permitted by applicable law, you release
            Wannafake and the other Wannafake Parties from responsibility,
            liability, claims, demands, and/or damages (actual and
            consequential) of every kind and nature, known and unknown
            (including, but not limited to, claims of negligence), arising out
            of or related to disputes between users and the acts or omissions of
            third parties. YOU EXPRESSLY WAIVE ANY RIGHTS YOU MAY HAVE UNDER
            CALIFORNIA CIVIL CODE 1542 AS WELL AS ANY OTHER STATUTE OR COMMON
            LAW PRINCIPLES THAT WOULD OTHERWISE LIMIT THE COVERAGE OF THIS
            RELEASE TO INCLUDE ONLY THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT
            TO EXIST IN YOUR FAVOR AT THE TIME OF AGREEING TO THIS RELEASE.
          </span>
        </p>
        <h4>
          <span>13. TRANSFER AND PROCESSING DATA</span>
        </h4>
        <p>
          <span>
            By accessing or using Wannafake, you acknowledge and, as applicable,
            consent to the processing, transfer and storage of information about
            you in and to the United States, countries in Europe and other
            countries.
          </span>
        </p>
        <h4>
          <span>14. DISPUTE RESOLUTION; BINDING ARBITRATION AGREEMENT</span>
        </h4>
        <p>
          <span>
            Please read the following section carefully because it requires
            users to arbitrate certain disputes and claims with Wannafake and
            limits the manner in which you can seek relief from us.
          </span>
        </p>
        <p>
          <span>Applicability of Arbitration Agreement.</span>
          <span>
            &nbsp;You and Wannafake waive your rights to a jury trial and to
            have any dispute arising out of or related to these Terms or
            Wannafake resolved in court. This Arbitration Agreement shall apply,
            without limitation, to all disputes or claims and requests for
            relief that arose or were asserted before the effective date of
            these Terms or any prior version of these Terms.
          </span>
        </p>
        <p>
          <span>Dispute resolution and arbitration</span>
          <span>
            . You and Wannafake agree that any dispute, claim, or controversy
            between you and Wannafake arising in connection with or relating in
            any way to these Terms or to your relationship with Wannafake as a
            user of Wannafake (whether based in contract, tort, statute, fraud,
            misrepresentation, or any other legal theory, and whether the claims
            arise during or after the termination of the Terms) will be
            determined by mandatory binding individual arbitration. Arbitration
            is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY
            IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED.
            There may be more limited discovery than in court. The arbitrator
            must follow this agreement and can award the same damages and relief
            as a court (including attorney fees), except that the arbitrator may
            not award declaratory or injunctive relief benefiting anyone but the
            parties to the arbitration.
          </span>
        </p>
        <p>
          <h6>Exceptions</h6>
          <span>
            Notwithstanding the above, you and Wannafake both agree that nothing
            herein will be deemed to waive, preclude, or otherwise limit either
            of our rights, at any time, to (1) bring an individual action in a
            small claims court, (2) pursue enforcement actions through
            applicable federal, state, or local agencies where such actions are
            available, (3) seek injunctive relief in a court of law, or (4) to
            file suit in a court of law to address intellectual property
            infringement claims.
          </span>
        </p>

        <p>
          <h6>Arbitration rules</h6>
          <span>
            Either you or Wannafake may start arbitration proceedings. Any
            arbitration between you and Wannafake will be finally settled by one
            arbitrator who will be chosen in accordance with the arbitration and
            internal rules of the european court of arbitration being part of
            the european centre of arbitration having its seat in strasbourg,
            and which are in force at the time the application for arbitration
            is filed, and of which adoption of this clause constitutes
            acceptance. The seat of arbitration shall be Strasbourg. The
            language of the proceedings shall be French. Applicable rules of
            substantive law shall be the law of France.
          </span>
        </p>
        <p>
          <h6>Time for filling</h6>
          <span>
            Any arbitration must be commenced by filing a demand for arbitration
            within ONE (1) YEAR after the date the party asserting the claim
            first knows or reasonably should know of the act, omission, or
            default giving rise to the claim; and there shall be no right to any
            remedy for any claim not asserted within that time period. If
            applicable law prohibits a one-year limitation period for asserting
            claims, any claim must be asserted within the shortest time period
            permitted by applicable law.
          </span>
        </p>
        <p>
          <h6>Notice, Process</h6>
          <span>
            A party who intends to seek arbitration must first send a written
            notice of the dispute to the other, by electronic mail ("Notice").
            Wannafake's email for notice is: legal@wannafake.com. The Notice
            must (1) describe the nature and basis of the claim or dispute; and
            (2) set forth the specific relief sought ("Demand"). We agree to use
            good faith efforts to resolve the claim directly, but if we do not
            reach an agreement to do so within 30 days after the Notice is
            received, you or Wannafake may commence an arbitration proceeding.
            During the arbitration, the amount of any settlement offer made by
            you or Wannafake shall not be disclosed to the arbitrator until
            after the arbitrator makes a final decision and award, if any. In
            the event our dispute is finally resolved through arbitration in
            your favor, Wannafake shall pay you (1) the amount awarded by the
            arbitrator, if any, (2) the last written settlement amount offered
            by Wannafake in settlement of the dispute prior to the arbitrator's
            award; or (3) $50.00, whichever is greater. All documents and
            information disclosed in the course of the arbitration shall be kept
            strictly confidential by the recipient and shall not be used by the
            recipient for any purpose other than for purposes of the arbitration
            or the enforcement of the arbitrator's decision and award and shall
            not be disclosed except in confidence to persons who have a need to
            know for such purposes or as required by applicable law. Except as
            required to enforce the arbitrator's decision and award, neither you
            nor Wannafake shall make any public announcement or public comment
            or originate any publicity concerning the arbitration, including,
            but not limited to, the fact that the parties are in dispute, the
            existence of the arbitration, or any decision or award of the
            arbitrator.
          </span>
        </p>
        <p>
          <h6>Modifications</h6>
          <span>
            In the event that Wannafake makes any future change to this
            arbitration provision (other than a change to Wannafake's email for
            Notice), you may reject any such change by sending us written notice
            within 30 days of the change to Wannafake's email for Notice, in
            which case your account with Wannafake shall be immediately
            terminated and this arbitration provision, as in effect immediately
            prior to the amendments you reject, shall survive.
          </span>
        </p>
        <p>
          <h6>Waiver of Class or Other Non-Individualized Relief</h6>
          <span>
            ALL DISPUTES, CLAIMS AND REQUESTS FOR RELIEF WITHIN THE SCOPE OF
            THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS
            AND NOT ON A CLASS OR COLLECTIVE BASIS. ONLY INDIVIDUAL RELIEF IS
            AVAILABLE, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR
            CONSOLIDATED WITH THOSE OF ANY OTHER USER.
          </span>
        </p>
        <p>
          <h6>Severability</h6>
          <span>
            Except as provided in this Section 14 above, if any part or parts of
            this Arbitration Agreement are found under the law to be invalid or
            unenforceable, then such specific part or parts shall be of no force
            and effect and shall be severed, and the remainder of the
            Arbitration Agreement shall continue in full force and effect.
          </span>
        </p>
        <p>
          <h6>Survival of Agreement</h6>
          <span>
            This Arbitration Agreement will survive the termination of your
            relationship with Wannafake.
          </span>
        </p>
        <h4>
          <span>15. GOVERNING LAW AND VENUE</span>
        </h4>
        <p>
          <span>
            These Terms and your access to and use of Wannafake will be governed
            by and construed and enforced in accordance with the laws of France,
            without regard to conflict of law rules or principles (of any other
            jurisdiction) that would cause the application of the laws of any
            other jurisdiction. The United Nations Convention for the
            International Sale of Goods does not apply to these Terms.
          </span>
        </p>
        <h4>
          <span>16. ELECTRONIC COMMUNICATIONS</span>
        </h4>
        <p>
          <span>
            By accessing or using Wannafake, you also consent to receive
            electronic communications from Wannafake (e.g., responses to your
            requests, questions and feedback, announcements, updates, and
            security alerts through email or by posting notices on Wannafake).
            You agree that any notices, agreements, disclosures or other
            communications that we send to you electronically will satisfy any
            legal communication requirements, including, but not limited to,
            that such communications be in writing.
          </span>
        </p>
        <h4>
          <span>17. TERMINATION</span>
        </h4>
        <p>
          <span>
            We reserve the right, without notice and in our sole discretion, to
            terminate your right to access or use Wannafake. We are not
            responsible for any loss or harm related to your inability to access
            or use Wannafake.
          </span>
        </p>
        <h4>
          <span>18. SEVERABILITY</span>
        </h4>
        <p>
          <span>
            If any provision or part of a provision of these Terms is unlawful,
            void or unenforceable, that provision or part of the provision is
            deemed severable from these Terms and does not affect the validity
            and enforceability of any remaining provisions.
          </span>
        </p>
        <h4>
          <span>19. MISCELLANEOUS</span>
        </h4>
        <p>
          <span>
            These terms constitute the entire agreement between you and
            Wannafake relating to your access to and use of Wannafake. The
            failure of Wannafake to exercise or enforce any right or provision
            of these Terms will not operate as a waiver of such right or
            provision. The section titles in these Terms are for convenience
            only and have no legal or contractual effect. Except as otherwise
            provided herein, these Terms are intended solely for the benefit of
            the parties and are not intended to confer third party beneficiary
            rights upon any other person or entity.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
      </div>
    </Container>
  );
};
