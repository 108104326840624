import { createSlice } from "@reduxjs/toolkit";

export const states = {
  IDLE: "idle",
  READY: "ready",
  WIP: "wip",
  ERROR: "error",
  SUCCESS: "success",
};

const slice = createSlice({
  name: "core",
  initialState: {
    selectedImage: null,
    selectedVideo: null,
    selectedVidFace: null,
    imgState: states.READY,
    imgErrorMessage: null,
    vidState: states.READY,
    vidErrorMessage: null,
    showAddImage: false,
    currentPage: 1,
    pageSize: 50,
  },
  reducers: {
    setSelectedImage(state, action) {
      state.selectedImage = action.payload;
    },
    setSelectedVideo(state, action) {
      state.selectedVideo = action.payload;
    },
    setSelectedVidFace(state, action) {
      state.selectedVidFace = action.payload;
    },
    setImgState(state, action) {
      state.imgState = action.payload;
    },
    setImgErrorMessage(state, action) {
      state.imgErrorMessage = action.payload;
    },
    setVidState(state, action) {
      state.vidState = action.payload;
    },
    setVidErrorMessage(state, action) {
      state.vidErrorMessage = action.payload;
    },
    setShowAddImage(state, action) {
      state.showAddImage = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
    toggle(state, action) {
      if (action.payload === "img") {
        switch (state.imgState) {
          case states.IDLE:
            state.imgState = states.READY;
            break;
          case states.READY:
            state.imgState = states.WIP;
            break;
          case states.WIP:
            state.imgState = states.ERROR;
            break;
          case states.ERROR:
            state.imgState = states.SUCCESS;
            break;
          case states.SUCCESS:
            state.imgState = states.IDLE;
            break;
          default:
            break;
        }
      } else {
        switch (state.vidState) {
          case states.IDLE:
            state.vidState = states.READY;
            break;
          case states.READY:
            state.vidState = states.WIP;
            break;
          case states.WIP:
            state.vidState = states.ERROR;
            break;
          case states.ERROR:
            state.vidState = states.SUCCESS;
            break;
          case states.SUCCESS:
            state.vidState = states.IDLE;
            break;
          default:
            break;
        }
      }
    },
  },
});

// Actions
export const {
  toggle,
  setSelectedImage,
  setSelectedVideo,
  setSelectedVidFace,
  setImgState,
  setImgErrorMessage,
  setVidState,
  setVidErrorMessage,
  setShowAddImage,
  setCurrentPage,
  setPageSize,
} = slice.actions;

// Selectors
export const getSelectedImage = (state) => state.core.selectedImage;
export const getSelectedVideo = (state) => state.core.selectedVideo;
export const getSelectedVidFace = (state) => state.core.selectedVidFace;
export const getImgState = (state) => state.core.imgState;
export const getImgErrorMessage = (state) => state.core.imgErrorMessage;
export const getVidState = (state) => state.core.vidState;
export const getShowAddImage = (state) => state.core.showAddImage;
export const getCurrentPage = (state) => state.core.currentPage;
export const getPageSize = (state) => state.core.pageSize;

// Reducers
export default slice.reducer;
